<template>
  <div class="filters">
    <button
      v-for="(filter, index) of filters"
      class="filters__item"
      :key="index"
      :class="{
        'filters__item--active': filter.name.toLowerCase() === selectedFilter,
      }"
      @click.prevent="selectFilter(filter.name)"
    >
      <AppIcon v-if="filter.name === 'Popular'" componentName="ZipperIcon" />
      {{ filter.name }}
    </button>

    <transition name="fade">
      <AppEmbedLoader v-if="loading" color="#4874e6" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppEmbedLoader from "@/components/ui/AppEmbedLoader";

export default {
  name: "BrowsTemplatesFilters",
  components: {
    AppEmbedLoader,
  },

  props: {
    initFilter: {
      type: String,
    },
  },

  computed: {
    ...mapGetters({
      filters: "templates/resumeFilters",
    }),
  },

  data() {
    return {
      selectedFilter: "popular",
      loading: false,
    };
  },

  methods: {
    selectFilter(filterName) {
      if (this.selectedFilter === filterName) return;

      this.$emit("selectFilter", filterName.toLowerCase());
      this.selectedFilter = filterName.toLowerCase();
    },
  },

  async created() {
    if (!this.filters.length) {
      await this.$store.dispatch("templates/getResumeFilters");
    }

    if (this.initFilter) {
      this.selectedFilter = this.initFilter;
    }
  },
};
</script>

<style lang="scss" scoped>
.filters {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  min-height: 40px;
  position: relative;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;

  @media (min-width: map_get($grid-breakpoints, xl)) {
    justify-content: center;
  }

  &::-webkit-scrollbar {
    height: 2px;
    background-color: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 8px;
    outline: none;
  }

  &__item {
    padding: 12px 25px;
    font-size: 14px;
    line-height: 17px;
    border: 1px solid #e4e4e4;
    border-radius: 32px;
    margin-right: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      margin-right: 0;
    }

    svg {
      margin-right: 12px;
      width: 12px;
      height: 16px;
      fill: $body-color;
    }

    &:hover,
    &--active {
      background-color: $primary;
      border-color: $primary;
      color: #ffffff;

      svg {
        fill: #fff;
      }
    }
  }
}
</style>
