<template>
  <div id="loader-wrapper">
    <div id="loader"></div>

    <div class="loader-section section-left"></div>
    <div class="loader-section section-right"></div>
    <div class="loader-text">
      <span v-html="loadingText"></span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Loader",

  computed: {
    ...mapGetters({
      loadingText: "loader/loadingText",
    }),
  },

  created() {
    // console.log("Apploader:", this.loadingText);
  },
};
</script>

<style lang="scss" scoped>
#content {
  margin: 0 auto;
  padding-bottom: 50px;
  width: 80%;
  max-width: 978px;
}

/* The Loader */

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
}

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: $primary;
  animation: spin 1.7s linear infinite;
  z-index: 11;

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $primary;
    animation: spin-reverse 0.6s linear infinite;
  }

  &:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $primary;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(#fff, 0.7);
  z-index: 10;
}

#loader-wrapper .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}

.loader-text {
  width: 65px;
  margin: 0 auto;
  z-index: 999;
  position: relative;
  top: 51%;
  color: #4874e6;
  font-size: 20px;
}

//
///* Loaded styles */
//
//#loader-wrapper .loader-section.section-left {
//  transform: translateX(-100%);
//  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
//}
//
//#loader-wrapper .loader-section.section-right {
//  transform: translateX(100%);
//  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
//}
//
//#loader {
//  opacity: 0;
//  transition: all 0.3s ease-out;
//}
//
//#loader-wrapper {
//  visibility: hidden;
//  transform: translateY(-100%);
//  transition: all 0.3s 1s ease-out;
//}
</style>
