<template>
  <section class="creation-layout">
    <main
      class="creation-main"
      id="creation-main"
      :class="{ active: !isModalVisible }"
    >
      <header class="creation-header">
        <div class="creation-header__logo">
          <img src="@/assets/images/header-logo.svg" alt="" />
        </div>

        <div v-if="!isEditingPage" class="step-count">
          Step {{ stepNumber }} of {{ totalCountStep }}
        </div>
      </header>

      <slot />
    </main>

    <component
      :is="sidebarComponent"
      @openPrevModal="openPreviewModal"
      @closePrevModal="closePreviewModal"
      :opened="isOpenSidebar"
      @closeSidebar="closeCreationSidebar"
    />

    <transition name="slide-right">
      <ChangeTemplateModal
        v-if="isOpenChangeTemplate"
        :currentTemplateId="templateId"
        :isResume="isResume"
      />
    </transition>
  </section>
</template>

<script>
import ChangeTemplateModal from "@/components/change-template/ChangeTemplateModal";

import { mapGetters } from "vuex";
import { eventBus } from "@/helpers/event-bus";
import { ROUT_PATH, TEMPLATE_EDITING_NAMES } from "@/helpers/rout-constants";
import {
  setIntermediateLetter,
  setIntermediateResume,
} from "@/helpers/save-intermediate-data";
import EditingHash from "@/helpers/editing-hash";
import ResumeHash from "@/helpers/resume-hash";
import LetterHash from "@/helpers/cover-letter-hash";

export default {
  name: "CreationFlowLayout",
  components: { ChangeTemplateModal },

  computed: {
    stepNumber() {
      return this.$route.meta.numberCreationStep;
    },

    isEditingPage() {
      return this.$route.meta?.isEditing;
    },

    sidebarComponent() {
      if (this.isEditingPage) {
        return () => import(`@/components/editing/SidebarEditing`);
      }

      return () => import(`@/components/creation/SidebarResumeCreation`);
    },

    totalCountStep() {
      return this.isResume ? 5 : 2;
    },

    isResume() {
      return (
        this.$route.fullPath.includes(
          ROUT_PATH.CREATING_RESUME_PATH.RESUME_CREATION
        ) || this.$route.name === TEMPLATE_EDITING_NAMES.RESUME_EDITING
      );
    },

    templateId() {
      return this.isResume ? this.resumeTemplateId : this.letterTemplateId;
    },

    ...mapGetters({
      resumeTemplateId: "resumeCreation/getResumeTemplateId",
      letterTemplateId: "coverLetterCreation/getLetterTemplateId",
    }),
  },

  data() {
    return {
      isModalVisible: false,
      isOpenSidebar: false,
      isOpenChangeTemplate: false,
    };
  },

  methods: {
    async openCreationSidebar() {
      this.isOpenSidebar = true;
      document.body.classList.add("body--overflow");

      if (this.isResume) {
        await setIntermediateResume(
          this.isEditingPage ? EditingHash.get() : ResumeHash.get(),
          true
        );

        return;
      }

      await setIntermediateLetter(
        this.isEditingPage ? EditingHash.get() : LetterHash.get(),
        true
      );
    },

    closeCreationSidebar() {
      this.isOpenSidebar = false;
      document.body.classList.remove("body--overflow");
    },
    openPreviewModal() {
      this.isModalVisible = true;
    },
    closePreviewModal() {
      this.isModalVisible = false;
    },
    openChangeTemplate() {
      this.isOpenChangeTemplate = true;
      document.body.classList.add("body--overflow");
    },

    closeChangeTemplate() {
      this.isOpenChangeTemplate = false;
      document.body.classList.remove("body--overflow");
    },
  },

  created() {
    document.body.classList.add("body-white");
    eventBus.$on("openCreationSidebar", this.openCreationSidebar);
    eventBus.$on("closeCreationSidebar", this.closeCreationSidebar);
    eventBus.$on("openChangeTemplateModal", this.openChangeTemplate);
    eventBus.$on("closeChangeTemplateModal", this.closeChangeTemplate);
  },

  beforeDestroy() {
    document.body.classList.remove("body-white");

    eventBus.$off("openCreationSidebar", this.openCreationSidebar);
    eventBus.$off("closeCreationSidebar", this.closeCreationSidebar);
    eventBus.$off("openChangeTemplateModal", this.openChangeTemplate);
    eventBus.$off("closeChangeTemplateModal", this.closeChangeTemplate);
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/modules/form.scss";
.creation-layout {
  @media (min-width: map_get($grid-breakpoints, lg)) {
    flex-wrap: nowrap;
    padding-right: 485px;
  }
}

.creation-main {
  width: 100%;
  max-width: 840px;
  margin: 0 auto;
  padding: 92px 24px 0 24px;
  position: relative;
  z-index: 0;

  @media (min-width: map_get($grid-breakpoints, md)) {
    padding: 0 24px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.creation-main.active {
  z-index: 13;
}
.creation-header {
  padding: 17px 24px;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(65, 64, 64, 0.05);
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(0);
  width: 100%;
  z-index: 0;

  @media (max-width: 991px) {
    padding: 17px 24px !important;
    z-index: 21 !important;
  }

  @media (min-width: map_get($grid-breakpoints, md)) {
    padding: 48px 0;
    box-shadow: none;
    position: static;
    flex-wrap: nowrap;
  }

  &__logo {
    img {
      height: 24px;

      @media (min-width: map_get($grid-breakpoints, lg)) {
        height: 30px;
      }
    }
  }

  .step-count {
    margin-left: auto;
    text-transform: uppercase;
    font-size: 14px;
    color: $light-gray;
    @media (max-width: 991px) {
      display: none;
    }
  }
}

::v-deep .creation-step {
  min-height: calc(100vh - 130px);
  display: flex;
  width: 100%;
  flex-direction: column;
  display: block;

  @media (min-width: map_get($grid-breakpoints, md)) {
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 48px;
  }
}

::v-deep .creation-form {
  width: 100%;
}

::v-deep .step-heading {
  margin-bottom: 32px;

  @media (min-width: map_get($grid-breakpoints, md)) {
    margin-bottom: 48px;
  }

  &__title {
    font-size: 24px;
    line-height: 130%;

    @media (min-width: map_get($grid-breakpoints, md)) {
      font-size: 32px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 130%;
    color: $light-gray;
    margin-top: 14px;

    @media (min-width: map_get($grid-breakpoints, md)) {
      font-size: 18px;
      margin-top: 16px;
    }
  }
}

::v-deep .creation-form-row {
  margin-left: -10px;
  margin-right: -10px;
  z-index: 0;
}

::v-deep .creation-form__col {
  padding: 0 10px;

  .account-form__field {
    margin-bottom: 40px;
  }
}

::v-deep .creation-form-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 48px 0 32px 0;
  margin-top: auto;

  @media (min-width: map_get($grid-breakpoints, md)) {
    padding-bottom: 48px;
    margin-top: auto;
    width: 100%;
  }

  @media (min-width: map_get($grid-breakpoints, xl)) {
    display: none;
  }

  &__preview {
    width: 100%;
    margin-bottom: 20px;

    @media (min-width: map_get($grid-breakpoints, lg)) {
      display: none !important;
    }
  }

  &__submit {
    width: 100%;

    @media (min-width: map_get($grid-breakpoints, lg)) {
      margin-left: auto;
    }
  }

  &__prev {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    padding-top: 24px;
    color: $body-color;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    &:hover {
      color: $primary;

      svg {
        fill: $primary;
      }
    }
  }
}

::v-deep .experience {
  margin: 0 0 48px;
  width: 100%;
  z-index: 0;

  &__item {
    margin-bottom: 24px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
  }
}
</style>
