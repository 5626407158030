import AccessToken from "@/helpers/access-token";
import router from "@/router";
import AppToast from "@/helpers/toast-methods";
import ResumeHash from "@/helpers/resume-hash";
import UncompletedCreationStep from "@/helpers/uncompleted-creation-step";
import LetterHash from "@/helpers/cover-letter-hash";
import CompletedTemplate from "@/helpers/completed-template-hash";
import EditingHash from "@/helpers/editing-hash";

export default {
  login({ commit }, payload) {
    return this._vm.$api.auth
      .login(payload)
      .then((resp) => {
        const token = resp.data.token;
        AccessToken.set(token);

        this.dispatch("user/setUserData", resp.data.user);
        commit("setAuthorized", true);

        // ResumeHash.clear();
        // LetterHash.clear();
        // commit("setAvailableUncompletedResume", false);

        return resp;
      })
      .catch((err) => {
        commit("setAuthorized", false);
        AccessToken.clear();
        return Promise.reject(err);
      });
  },

  loginSocial({ commit }, payload) {
    return this._vm.$api.auth
      .loginSocial(payload)
      .then((resp) => {
        const token = resp.data.token;
        AccessToken.set(token);

        this.dispatch("user/setUserData", resp.data.user);
        commit("setAuthorized", true);

        return resp;
      })
      .catch((err) => {
        commit("setAuthorized", false);
        AccessToken.clear();
        return Promise.reject(err);
      });
  },

  logout({ dispatch }) {
    return this._vm.$api.auth
      .logout()
      .then(async (resp) => {
        await dispatch("removeAllCredentials");

        await this.dispatch(
          "user/setUserData",
          this.getters["user/defaultUserData"]
        );

        router.push("/").catch(() => {
          return false;
        });
        return resp;
      })
      .catch(async (err) => {
        await dispatch("removeAllCredentials");
        await router.push("/");
        return Promise.reject(err);
      });
  },

  removeAllCredentials({ commit }) {
    commit("setAuthorized", false);
    AccessToken.clear();
    ResumeHash.clear();
    CompletedTemplate.clear();
    LetterHash.clear();
    EditingHash.clear();
    UncompletedCreationStep.clear();
    commit("setAvailableUncompletedResume", false);
  },

  register({ commit }, payload) {
    return this._vm.$api.auth
      .register(payload)
      .then((resp) => {
        const token = resp.data.token;
        AccessToken.set(token);

        this.dispatch("user/setUserData", resp.data.user);
        commit("setAuthorized", true);

        ResumeHash.clear();
        UncompletedCreationStep.clear();
        LetterHash.clear();
        commit("setAvailableUncompletedResume", false);

        return resp;
      })
      .catch((err) => {
        commit("setAuthorized", false);
        AccessToken.clear();
        return Promise.reject(err);
      });
  },

  resetPass(store, payload) {
    return this._vm.$api.auth
      .resetPassword(payload)
      .then((resp) => {
        AppToast.toastSuccess(
          resp?.data?.message || "We have e-mailed your password reset"
        );

        return resp;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  setNewPassword(store, payload) {
    return this._vm.$api.auth
      .setNewPassword(payload)
      .then(async (resp) => {
        AppToast.toastSuccess(resp?.data?.message || "Password was changed");
        await this.dispatch("auth/login", payload);
        router.push("/");
        return resp;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};
