import ResumeHash from "@/helpers/resume-hash";
import { cloneDeep } from "lodash/lang";

export default {
  getResume(state) {
    return cloneDeep(state.resume);
  },

  getDefaultResume() {
    return {
      hash: null,
      city: null,
      country: null,
      educations: [],
      email: null,
      first_name: null,
      last_name: null,
      phone: null,
      photo: null,
      professional_summary: null,
      skills: [],
      state: null,
      street_address: null,
      template_id: null,
      work_experience: null,
      work_histories: [],
    };
  },

  getResumeHash(state) {
    return state.resume.hash;
  },

  getResumePreview(state) {
    return state.resumePreview || require("@/assets/images/final-preview.jpg");
  },

  getResumePreviewAll(state) {
    let imageFiles = state.resumePreviewFilesImage;

    if (imageFiles == null || imageFiles[0] == null) {
      imageFiles = [require("@/assets/images/final-preview.jpg")];
    }

    return imageFiles;
  },

  getResumePreviewPDF(state) {
    return state.resumePreviewPDF;
  },

  isLoadingPreview(state) {
    return state.isLoadResumePreview;
  },

  getResumeTemplateId(state) {
    return String(state.resume?.template_id);
  },

  getDefaultWorkHistory() {
    return {
      resume_hash: ResumeHash.get(),
      index: null,
      job_title: null,
      employer: null,
      city: null,
      state: null,
      start_date: null,
      end_date: null,
      responsibilities: "",
    };
  },

  getWorkHistory(state) {
    return state.resume?.work_histories || [];
  },

  getDefaultEducation() {
    return {
      resume_hash: ResumeHash.get(),
      index: null,
      college_name: null,
      college_location: null,
      level_of_education: null,
      field_of_study: null,
      graduation_month: null,
      education_year: null,
      training_programs: null,
    };
  },

  getEducations(state) {
    return state.resume?.educations || [];
  },

  getDefaultSkill() {
    return {
      index: null,
      name: null,
      level: null,
    };
  },

  getSkills(state) {
    return state.resume?.skills || [];
  },
};
