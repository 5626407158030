import {
  TEMPLATE_EDITING_NAMES,
  LAYOUT_NAMES,
  ROUT_PATH,
} from "@/helpers/rout-constants";

export default [
  {
    path: ROUT_PATH.TEMPLATE_EDITING.RESUME_EDITING,
    name: TEMPLATE_EDITING_NAMES.RESUME_EDITING,
    component: () => import("@/views/template-editing/ResumeEditing"),
    meta: {
      layout: LAYOUT_NAMES.CREATION,
      requiresAuth: true,
      isEditing: true,
    },
  },
  {
    path: ROUT_PATH.TEMPLATE_EDITING.COVER_LETTER_EDITING,
    name: TEMPLATE_EDITING_NAMES.COVER_LETTER_EDITING,
    component: () => import("@/views/template-editing/CoverLetterEditing"),
    meta: {
      layout: LAYOUT_NAMES.CREATION,
      requiresAuth: true,
      isEditing: true,
    },
  },
];
