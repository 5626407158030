<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>

    <transition name="fade">
      <AppLoader v-if="loading" />
    </transition>

    <portal-target name="replacePrefilledModal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CookieHelper from "@/helpers/cookie-helper";
import * as FullStory from "@fullstory/browser";
import $ from "jquery";

export default {
  computed: {
    ...mapGetters({
      loading: "loader/loading",
      isLoggedIn: "auth/isLoggedIn",
    }),
    layout() {
      return this.$route.meta.layout;
    },
  },

  created() {
    if (this.isLoggedIn) {
      this.$store.dispatch("user/getUser");
    }

    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("ppg")) {
      CookieHelper.setCookie("ppg", urlParams.get("ppg"), 30);
    }
    if (urlParams.has("source")) {
      CookieHelper.setCookie("source", urlParams.get("source"), 30);
    }
    if (urlParams.has("mode")) {
      CookieHelper.setCookie("mode", urlParams.get("mode"), 30);
    }
    if (urlParams.has("keyword")) {
      CookieHelper.setCookie("keyword", urlParams.get("keyword"), 30);
    }
    if (urlParams.has("geo")) {
      CookieHelper.setCookie("geo", urlParams.get("geo"), 30);
    }
    if (urlParams.has("home_cta")) {
      CookieHelper.setCookie("home_cta", urlParams.get("home_cta"), 30);
    }
    if (urlParams.has("cta_bg")) {
      CookieHelper.setCookie("cta_bg", urlParams.get("cta_bg"), 30);
    }

    if (urlParams.has("skip_template")) {
      CookieHelper.setCookie(
        "skip_template",
        urlParams.get("skip_template"),
        30
      );
    }
    if (urlParams.has("pricing")) {
      CookieHelper.setCookie("pricing", urlParams.get("pricing"), 30);
    }
    if (urlParams.has("skip_reg")) {
      CookieHelper.setCookie("skip_reg", urlParams.get("skip_reg"), 30);
    }
    if (urlParams.has("back_btn")) {
      CookieHelper.setCookie("back_btn", urlParams.get("back_btn"), 30);
    }
    if (urlParams.has("hide_add")) {
      CookieHelper.setCookie("hide_add", urlParams.get("hide_add"), 30);
    }
    if (urlParams.has("hide_doctype")) {
      CookieHelper.setCookie("hide_doctype", urlParams.get("hide_doctype"), 30);
    }

    FullStory.init({ orgId: "129M5P" });
  },
  methods: {},
};

// $(document).ready(function () {
// $(".creation-form .creation-mobile-form-footer").css({ position: "fixed" });

$(document)
  .on("focus", "input, .ql-editor", function (e) {
    $(".creation-mobile-form-footer").addClass("static");
    $(".footerbtn").css({ padding: "0", "padding-top": "5px" });
    e.preventDefault();
  })
  .on("focusout", "input, .ql-editor", function (e) {
    $(".creation-mobile-form-footer").removeClass("static");
    $(".footerbtn").css({ padding: "0 15px" });
    e.preventDefault();
  });

// remove the listener
// $(document).off("click", "input");
// });
</script>

<style lang="scss">
#app {
  overflow-x: hidden;
}
@import "assets/scss/app.scss";
</style>
