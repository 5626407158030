import {
  USER_ACCOUNT_NAMES,
  LAYOUT_NAMES,
  ROUT_PATH,
} from "@/helpers/rout-constants";

export default [
  {
    path: ROUT_PATH.USER_ACCOUNT,
    name: USER_ACCOUNT_NAMES.USER_ACCOUNT,
    component: () => import("@/views/account/UserAccount"),
    meta: {
      layout: LAYOUT_NAMES.DEFAULT,
      requiresAuth: true,
    },
  },
  {
    path: ROUT_PATH.USER_DASHBOARD,
    name: USER_ACCOUNT_NAMES.USER_DASHBOARD,
    component: () => import("@/views/dashboard/UserDashboard"),
    meta: {
      layout: LAYOUT_NAMES.DEFAULT,
      requiresAuth: true,
    },
  },
  {
    path: ROUT_PATH.USER_TEMPLATES.USER_RESUME_DETAILS,
    name: USER_ACCOUNT_NAMES.USER_RESUME_DETAILS,
    component: () =>
      import("@/views/dashboard/template-details/TemplateDetails"),
    meta: {
      layout: LAYOUT_NAMES.DEFAULTNOLINKS,
      requiresAuth: true,
      templateType: "resume",
    },
  },
  {
    path: ROUT_PATH.USER_TEMPLATES.USER_LETTER_DETAILS,
    name: USER_ACCOUNT_NAMES.USER_LETTER_DETAILS,
    component: () =>
      import("@/views/dashboard/template-details/TemplateDetails"),
    meta: {
      layout: LAYOUT_NAMES.DEFAULTNOLINKS,
      requiresAuth: true,
      templateType: "letter",
    },
  },
];
