export default {
  getUserGeoIP() {
    return this._vm.$api.geoip
      .getUserGeoIP()
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  getUserGeoLocation(store, request) {
    return this._vm.$api.geoip
      .getUserGeoLocation(request)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};
