export default {
  namespaced: true,
  actions: {
    contactUs(store, payload) {
      return this._vm.$api.contact
        .contactUs(payload)
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
  },
};
