import $http from "./BaseHttpServices";
import ResumeHash from "@/helpers/resume-hash";
import CompletedTemplate from "@/helpers/completed-template-hash";
import AccessToken from "@/helpers/access-token";

let uploadResumeRequest = null;
let updatePreviewResumeRequest = null;

export default {
  getResumeFilters() {
    return $http.get("template-tags");
  },

  getResumeTemplates(filter) {
    const params =
      filter !== "all" ? { params: { tag: filter.toLowerCase() } } : null;

    return $http.get("resume-templates-previews", params || null);
  },

  getResume(hash) {
    const resumeHash = hash || ResumeHash.get();
    return $http.get(`resume/${resumeHash}`);
  },

  createResume(payload) {
    return $http.post("resume", payload);
  },

  updateResume(payload) {
    if (uploadResumeRequest) {
      uploadResumeRequest.cancel({ withoutToast: true });
    }

    uploadResumeRequest = $http.CancelToken.source();

    return $http.patch("resume", payload, {
      cancelToken: uploadResumeRequest.token,
    });
  },

  forceUpdateResume(payload) {
    return $http.patch("resume", payload);
  },

  uploadResumePhoto(payload) {
    return $http.post("resume/photo", payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  createResumeWorkHistory(payload) {
    return $http.post("work-history", payload);
  },

  updateResumeWorkHistory(payload) {
    return $http.patch("work-history", payload);
  },

  updateResumeWorkHistoryGroup(payload) {
    return $http.patch("work-history/update-index-orders", payload);
  },

  removeResumeWorkHistory(payload) {
    return $http.delete(`work-history/${payload.hash}/${payload.id}`);
  },

  getResumeWorkPrefilled(querySearch) {
    return $http.get("auto-complete/pop-workhistory", {
      params: { job_role: querySearch?.toLowerCase() || "" },
    });
  },

  getResumeProSummaryPrefilled(querySearch) {
    return $http.get("auto-complete/pop-professionals-summary", {
      params: { job_role: querySearch?.toLowerCase() || "" },
    });
  },

  createResumeEducation(payload) {
    return $http.post("education", payload);
  },

  updateResumeEducation(payload) {
    return $http.patch("education", payload);
  },

  updateEducationGroup(payload) {
    return $http.patch("education/update-index-orders", payload);
  },

  removeResumeEducation(payload) {
    return $http.delete(`education/${payload.hash}/${payload.id}`);
  },

  getResumeEducationPrefilled(querySearch) {
    return $http.get("auto-complete/pop-training-programs", {
      params: { q: querySearch?.toLowerCase() || "" },
    });
  },

  getResumeEducationLevels() {
    return $http.get("auto-complete/pop-education-levels");
  },

  createResumeSkill(payload) {
    return $http.post("skill", payload);
  },

  updateResumeSkill(payload) {
    return $http.patch("skill", payload);
  },

  updateSkillGroup(payload) {
    return $http.patch("skill/update-index-orders", payload);
  },

  removeResumeSkill(payload) {
    return $http.delete(`skill/${payload.hash}/${payload.id}`);
  },

  getResumeSkills(payload) {
    return $http.get("auto-complete/pop-skills", {
      params: {
        q: payload.querySearch?.toLowerCase() || "",
        job_role: payload.jobRole?.toLowerCase() || "",
      },
    });
  },

  getResumeSummaryPrefilled(querySearch) {
    return $http.get("auto-complete/pop-professionals-summary", {
      params: { job_role: querySearch?.toLowerCase() || "" },
    });
  },

  getJobTitles(querySearch) {
    return $http.get("auto-complete/pop-job-roles", {
      params: { q: querySearch?.toLowerCase() || "" },
    });
  },
  getStudyField(querySearch) {
    return $http.get("auto-complete/pop-fields-of-study", {
      params: { q: querySearch?.toLowerCase() || "" },
    });
  },
  getCompanies(querySearch) {
    return $http.get("auto-complete/pop-companies", {
      params: { q: querySearch?.toLowerCase() || "" },
    });
  },

  getResumePreview(hash) {
    if (updatePreviewResumeRequest) {
      updatePreviewResumeRequest.cancel({ withoutToast: true });
    }

    updatePreviewResumeRequest = $http.CancelToken.source();

    const resumeHash = hash || ResumeHash.get();
    return $http.get(`resume-preview/${resumeHash}`, {
      cancelToken: updatePreviewResumeRequest.token,
      params: {
        token: AccessToken.get() || "",
      },
      responseType: "blob",
    });
  },

  getResumePreviewAll(hash) {
    if (updatePreviewResumeRequest) {
      updatePreviewResumeRequest.cancel({ withoutToast: true });
    }

    updatePreviewResumeRequest = $http.CancelToken.source();

    const resumeHash = hash || ResumeHash.get();
    return $http.get(`resume-preview-all/${resumeHash}`, {
      cancelToken: updatePreviewResumeRequest.token,
      params: {
        token: AccessToken.get() || "",
      },
    });
  },

  uploadUsersResume(payload) {
    return $http.post("resume/upload", payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  bindResumeToUser() {
    const payload = { resume_hashes: [CompletedTemplate.get().hash] };

    return $http.post("auth/link-resume", payload);
  },

  downloadResume(hash, type) {
    return $http.get("auth/download-resume", {
      params: { hash, type },
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    });
  },

  duplicateResume(hash, isHidden = false) {
    return $http.get(`resume/clone/${hash}`, {
      params: { is_hidden: !!isHidden },
    });
  },
};
