<template>
  <div class="empty-layout">
    <header class="header">
      <div class="header__logo">
        <img src="@/assets/images/header-logo.svg" alt="" />
      </div>
    </header>

    <slot />
  </div>
</template>

<script>
export default {
  name: "EmptyLayout",
};
</script>

<style lang="scss" scoped>
.empty-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  padding-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    padding-top: 93px;
  }

  &__logo {
    img {
      height: 32px;

      @media (min-width: map_get($grid-breakpoints, lg)) {
        height: 40px;
      }
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 24px;
  padding-top: 32px;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    padding-bottom: 48px;
    padding-top: 0;
  }

  &__link {
    font-size: 14px;
    color: $body-color;

    &:hover {
      color: $primary;
    }
  }
}
</style>
