import { cloneDeep } from "lodash/lang";

export default {
  getCoverLetter(state) {
    return cloneDeep(state.coverLetter);
  },

  getLetterHash(state) {
    return state.coverLetter.hash;
  },

  getCoverLetterPreview(state) {
    return (
      state.coverLetterPreview || require("@/assets/images/preview-changes.jpg")
    );
  },

  getCoverLetterPreviewAll(state) {
    let imageFiles = state.coverLetterPreviewImageFiles;

    if (imageFiles == null || imageFiles[0] == null) {
      imageFiles = [require("@/assets/images/final-preview.jpg")];
    }

    return imageFiles;
  },

  getCoverLetterPreviewPDF(state) {
    return state.coverLetterPreviewPDF;
  },

  getLetterTemplateId(state) {
    return String(state.coverLetter?.template_id);
  },

  isLoadingPreview(state) {
    return state.isLoadLetterPreview;
  },
};
