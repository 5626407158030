<template>
  <div class="burger-menu d-lg-none" :class="{ 'burger-menu--open': isOpen }">
    <h2 class="burger-menu__title">Menu</h2>

    <router-link
      v-if="isLoggedIn && userInfo"
      :to="ROUT_PATH.USER_ACCOUNT"
      class="menu-user"
    >
      <figure class="menu-user__ava">
        <img
          :src="userInfo.photo || require('@/assets/images/no-ava.svg')"
          alt=""
        />
      </figure>

      <div class="menu-user-info">
        <p class="menu-user-info__name">
          {{ userInfo.first_name }} {{ userInfo.last_name }}
        </p>

        <div class="menu-user-info__btn">
          <AppIcon componentName="EditIcon" />
          <span>Edit Profile</span>
        </div>
      </div>
    </router-link>

    <AppButton
      v-else
      title="Log In"
      className="secondary"
      class="burger-menu__btn"
      tag="router-link"
      :to="ROUT_PATH.AUTH.LOGIN"
    />

    <nav class="burger-menu-list">
      <router-link
        v-if="isLoggedIn"
        :to="ROUT_PATH.USER_DASHBOARD"
        class="burger-menu-list__item"
        >Dashboard
      </router-link>
      <router-link
        :to="ROUT_PATH.BROWS_RESUME_TEMPLATES"
        class="burger-menu-list__item"
        >Resume Templates
      </router-link>
      <router-link
        :to="ROUT_PATH.BROWS_LETTERS_TEMPLATES"
        class="burger-menu-list__item"
        >Cover Letter Templates
      </router-link>
    </nav>

    <button
      v-if="isLoggedIn"
      class="burger-menu-logout burger-menu-list__item"
      @click.prevent="onLogout"
    >
      <AppIcon componentName="LogoutIcon" />
      Log Out
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ROUT_PATH } from "@/helpers/rout-constants";

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      ROUT_PATH,
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      userInfo: "user/user",
    }),
  },

  methods: {
    async onLogout() {
      await this.$store.dispatch("loader/pending");
      try {
        await this.logout();
      } catch (e) {
        console.error(e);
      }
      await this.$store.dispatch("loader/done");
    },
    ...mapActions({
      logout: "auth/logout",
    }),
  },
};
</script>

<style lang="scss" scoped>
.burger-menu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 300px;
  background-color: $primary;
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 20px 4px 48px rgba(0, 0, 0, 0.15);
  transform: translateX(-120%);
  transition: transform 0.3s ease;

  &__title {
    color: #fff;
  }

  ::v-deep .burger-menu__btn.burger-menu__btn {
    padding: 5px 20px;
    width: 100%;
    height: 40px;
    border-color: #fff;
    margin-top: 40px;
    margin-bottom: 60px;

    .btn__text {
      color: #fff;
    }
  }

  &--open {
    transform: translateX(0);
  }
}

.menu-user {
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 60px;

  &__ava {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 15px;
    overflow: hidden;
    background-color: white;
    background-size: contain;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  .menu-user-info {
    &__name {
      color: #ffffff;
      font-size: 18px;
      line-height: 27px;
    }

    &__btn {
      display: inline-flex;
      align-items: center;

      span {
        text-decoration: underline;
        font-size: 12px;
        line-height: 140%;
        color: #fff;
      }

      svg {
        width: 16px;
        height: 16px;
        fill: #fff;
        margin-right: 8px;
      }
    }
  }
}

.burger-menu-list {
  &__item {
    display: inline-flex;
    font-size: 18px;
    color: #fff;
    margin-bottom: 30px;
    line-height: 27px;
  }
}

.burger-menu-logout {
  align-items: center;
  margin-bottom: 0;
  margin-top: auto;
  width: 100%;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    fill: #fff;
  }
}
</style>
