import ResumeHash from "@/helpers/resume-hash";
import AppToast from "@/helpers/toast-methods";
import router from "@/router";
import UncompletedCreationStep from "@/helpers/uncompleted-creation-step";

export default {
  getResume({ commit }, hash = null) {
    return this._vm.$api.resume
      .getResume(hash)
      .then(async (resp) => {
        commit("setResume", resp.data);

        return resp.data;
      })
      .catch((err) => {
        ResumeHash.clear();
        UncompletedCreationStep.clear();
        this.commit("auth/setAvailableUncompletedResume", false);
        router.push("/");

        return Promise.reject(err);
      });
  },

  createResume({ commit }, resumeData) {
    return this._vm.$api.resume
      .createResume(resumeData)
      .then((resp) => {
        ResumeHash.set(resp.data.hash);
        commit("setResume", resp.data);
        this.commit("auth/setAvailableUncompletedResume", true);

        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  uploadUsersResume({ commit }, payload) {
    return this._vm.$api.resume
      .uploadUsersResume(payload)
      .then(async (resp) => {
        ResumeHash.set(resp.data.hash);
        commit("setResume", resp.data);

        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  updateResumeField({ commit }, updateData) {
    commit("updateResumeField", updateData);
  },

  /**
   * Can be canceled
   * @param commit
   * @param resumeData
   * @returns Promise
   */
  updateResume({ commit }, resumeData) {
    const updateResumeData = {
      hash: this.getters["resumeCreation/getResumeHash"] || ResumeHash.get(),
      ...resumeData,
    };

    return this._vm.$api.resume
      .updateResume(updateResumeData)
      .then((resp) => {
        commit("mergeResume", updateResumeData);

        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  /**
   * Cant be canceled
   * @param _
   * @param resumeData
   * @returns Promise
   */
  forceUpdateResume(_, resumeData) {
    return this._vm.$api.resume
      .forceUpdateResume(resumeData)
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  uploadResumePhoto({ commit }, payload) {
    return this._vm.$api.resume
      .uploadResumePhoto(payload)
      .then((resp) => {
        // AppToast.toastSuccess("Resume photo has been successfully changed");

        const updatedData = {
          key: "photo",
          value: resp.data.photo,
        };

        commit("updateResumeField", updatedData);

        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  createWorkHistory(store, workHistory) {
    return this._vm.$api.resume
      .createResumeWorkHistory(workHistory)
      .then(async (resp) => {
        await this.dispatch(
          "resumeCreation/getResume",
          workHistory.resume_hash
        );
        // AppToast.toastSuccess("Work history was successfully created!");

        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  updateWorkHistory(store, workHistory) {
    return this._vm.$api.resume
      .updateResumeWorkHistory(workHistory)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  updateWorkHistoryGroup(store, workHistories) {
    return this._vm.$api.resume
      .updateResumeWorkHistoryGroup(workHistories)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  removeWorkHistory(store, payload) {
    return this._vm.$api.resume
      .removeResumeWorkHistory(payload)
      .then(async (resp) => {
        await this.dispatch("resumeCreation/getResume", payload.hash);
        // AppToast.toastSuccess("Work history was successfully deleted!");
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  getResumeWorkPrefilled(store, querySearch = "") {
    return this._vm.$api.resume
      .getResumeWorkPrefilled(querySearch)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  createEducation(store, education) {
    return this._vm.$api.resume
      .createResumeEducation(education)
      .then(async (resp) => {
        await this.dispatch("resumeCreation/getResume", education.resume_hash);
        // AppToast.toastSuccess("Education was successfully created!");

        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  updateEducation(store, educationId) {
    return this._vm.$api.resume
      .updateResumeEducation(educationId)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  updateEducationGroup(_, educations) {
    return this._vm.$api.resume
      .updateEducationGroup(educations)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  removeEducation(store, payload) {
    return this._vm.$api.resume
      .removeResumeEducation(payload)
      .then(async (resp) => {
        await this.dispatch("resumeCreation/getResume", payload.hash);
        // AppToast.toastSuccess("Education was successfully deleted!");
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  getResumeEducationPrefilled(store, querySearch = "") {
    return this._vm.$api.resume
      .getResumeEducationPrefilled(querySearch)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  getResumeEducationLevels() {
    return this._vm.$api.resume
      .getResumeEducationLevels()
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  createSkill(store, skill) {
    return this._vm.$api.resume
      .createResumeSkill(skill)
      .then(async (resp) => {
        await this.dispatch("resumeCreation/getResume", skill.resume_hash);
        // AppToast.toastSuccess("Skill was successfully created!");

        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  updateSkill(store, educationId) {
    return this._vm.$api.resume
      .updateResumeSkill(educationId)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  updateSkillGroup(store, educations) {
    return this._vm.$api.resume
      .updateSkillGroup(educations)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  removeSkill(store, payload) {
    return this._vm.$api.resume
      .removeResumeSkill(payload)
      .then(async (resp) => {
        await this.dispatch("resumeCreation/getResume", payload.hash);
        // AppToast.toastSuccess("Skill was successfully deleted!");
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  getResumeSkills(store, querySearch = "") {
    return this._vm.$api.resume
      .getResumeSkills(querySearch)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  getJobTitles(store, querySearch = "") {
    return this._vm.$api.resume
      .getJobTitles(querySearch)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  getCompanies(store, querySearch = "") {
    return this._vm.$api.resume
      .getCompanies(querySearch)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  getStudyField(store, querySearch = "") {
    return this._vm.$api.resume
      .getStudyField(querySearch)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  getResumeSummaryPrefilled(store, querySearch = "") {
    return this._vm.$api.resume
      .getResumeSummaryPrefilled(querySearch)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  setPreviewLoadingState({ commit }, stateStatus) {
    commit("setPreviewLoadingState", stateStatus);
  },

  getResumePreview({ commit, dispatch }, hash = null) {
    dispatch("setPreviewLoadingState", true);

    return this._vm.$api.resume
      .getResumePreview(hash || this.getters["resumeCreation/getResume"].hash)
      .then(async (resp) => {
        const previewLink = `${URL.createObjectURL(resp.data)}`;

        // const previewLink = `${resp.data.url}?v=${Date.now()}`;

        commit("setPreviewImage", previewLink);
        dispatch("setPreviewLoadingState", false);
        return resp.data;
      })
      .catch((err) => {
        dispatch("setPreviewLoadingState", false);
        return Promise.reject(err);
      });
  },

  getResumePreviewAll({ commit, dispatch }, hash = null) {
    dispatch("setPreviewLoadingState", true);

    return this._vm.$api.resume
      .getResumePreviewAll(
        hash || this.getters["resumeCreation/getResume"].hash
      )
      .then(async (resp) => {
        const modifiedPreviewFiles = [];
        const previewFiles =
          resp.data.preview_image_files != undefined
            ? resp.data.preview_image_files
            : "{}";

        const previewPDF =
          resp.data.generated_pdf != undefined ? resp.data.generated_pdf : "";

        const arrPreviewFiles = JSON.parse(previewFiles);
        for (var i = 0; i < arrPreviewFiles.length; i++) {
          modifiedPreviewFiles.push(`${arrPreviewFiles[i]}?v=${Date.now()}`);
        }

        commit("setPreviewFilesImage", modifiedPreviewFiles);
        commit("setPreviewPDF", previewPDF);

        dispatch("setPreviewLoadingState", false);
        return resp.data;
      })
      .catch((err) => {
        dispatch("setPreviewLoadingState", false);
        return Promise.reject(err);
      });
  },

  bindResumeToUser() {
    return this._vm.$api.resume
      .bindResumeToUser()
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  downloadResume(_, fileInfo) {
    return this._vm.$api.resume
      .downloadResume(fileInfo.hash, fileInfo.type)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  duplicateResume(_, duplicateParams) {
    return this._vm.$api.resume
      .duplicateResume(duplicateParams.hash, duplicateParams.isHidden)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  cleanResume({ commit }) {
    commit("setResume", null);
  },
};
