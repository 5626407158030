export default {
  getResumeFilters({ commit }) {
    return this._vm.$api.resume
      .getResumeFilters()
      .then((resp) => {
        const filters = resp.data;
        filters.unshift({
          name: "All",
          image: null,
        });

        return commit("setResumeTemplateFilters", filters);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  getResumeTemplates({ commit }, filter) {
    return this._vm.$api.resume
      .getResumeTemplates(filter)
      .then((resp) => {
        return commit("setResumeTemplates", resp.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  getCoverLetterTemplates({ commit }) {
    return this._vm.$api.coverLetter
      .getCoverLetterPreviewTemplates()
      .then((resp) => commit("setCoverLetterTemplates", resp.data))
      .catch((err) => Promise.reject(err));
  },
};
