import Vue from "vue";

export default {
  setResumeTemplates(state, resumeTemplates) {
    Vue.set(state, "resumeTemplates", resumeTemplates);
  },

  setCoverLetterTemplates(state, letterTemplates) {
    Vue.set(state, "letterTemplates", letterTemplates);
  },

  setResumeTemplateFilters(state, filters) {
    Vue.set(state, "filters", filters);
  },
};
