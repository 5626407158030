import AppToast from "@/helpers/toast-methods";
import AccessToken from "@/helpers/access-token";

export default {
  getUser({ dispatch }) {
    return this._vm.$api.user
      .getUser()
      .then((resp) => {
        if (resp.data.photo) {
          resp.data.photo = `${
            resp.data.photo
          }?v=${Date.now()}&token=${AccessToken.get()}`;
        }

        dispatch("setUserData", resp.data);

        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  setUserData({ commit }, userData) {
    if (userData.photo) {
      userData.photo = `${
        userData.photo
      }?v=${Date.now()}&token=${AccessToken.get()}`;
    }

    commit("setUserData", userData);
    commit("setExpiresDate", userData.payment_expire_at);
    commit("setSubscribeState", userData.payment_expire_at);
  },

  updateUserInfo(store, userInfo) {
    return this._vm.$api.user
      .updateUserInfo(userInfo)
      .then((resp) => {
        AppToast.toastSuccess(resp?.data?.message);

        return this.dispatch("user/getUser");
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  uploadUserPhoto({ dispatch }, photo) {
    return this._vm.$api.user
      .uploadUserPhoto(photo)
      .then((resp) => {
        AppToast.toastSuccess("User's photo has been successfully changed");

        if (resp.data.photo) {
          resp.data.photo = `${
            resp.data.photo
          }?v=${Date.now()}&token=${AccessToken.get()}`;
        }
        dispatch("setUserData", resp.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  removeUserPhoto({ commit }) {
    return this._vm.$api.user
      .removeUserPhoto()
      .then((resp) => {
        AppToast.toastSuccess(resp?.data?.message);

        commit("removeUserPhoto");
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};
