<template>
  <section class="templates-modal-section">
    <div
      class="templates-modal-mask d-none d-md-block"
      @click.prevent="closeChangeTemplateModal"
    ></div>

    <button
      type="button"
      class="close-modal-btn d-none d-md-inline-flex"
      @click.prevent="closeChangeTemplateModal"
    >
      <AppIcon componentName="CloseIcon" />
    </button>

    <header class="templates-modal-header d-md-none">
      <router-link to="/" class="templates-modal-header__logo">
        <img src="@/assets/images/header-logo.svg" alt="" />
      </router-link>

      <button
        type="button"
        class="templates-modal-header__close"
        @click.prevent="closeChangeTemplateModal"
      >
        <AppIcon componentName="CloseIcon" />
      </button>
    </header>

    <div
      class="templates-modal-wrap"
      :class="{ 'templates-modal-wrap--loading': loading }"
    >
      <div class="templates-modal">
        <h2 class="templates-modal__title">Change Template</h2>
        <p class="templates-modal__text">
          Don't worry. All information will be saved and applied on a new
          template
        </p>

        <BrowsTemplatesFilters
          v-if="isResume"
          class="templates-modal__filters"
          initFilter="all"
          @selectFilter="getResumeTemplates"
        />

        <div v-if="templates.length" class="row">
          <div
            v-for="template of templates"
            :key="template.id"
            class="col-12 col-md-6 col-xl-4"
          >
            <BrowsTemplatesArticle
              :template="template"
              :selected="currentTemplateId === String(template.id)"
              :isResume="isResume"
              isChangeTemplateModal
              @chooseTemplate="changeTemplate"
            />
          </div>
        </div>
      </div>

      <transition name="fade">
        <div v-if="loading" class="templates-modal-loader">
          <AppEmbedLoader :size="100" color="#4874e6" />
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/helpers/event-bus";
import AppToast from "@/helpers/toast-methods";

import BrowsTemplatesFilters from "@/components/brows-templates/BrowsTemplatesFilters";
import BrowsTemplatesArticle from "@/components/brows-templates/BrowsTemplatesArticle";
import AppEmbedLoader from "@/components/ui/AppEmbedLoader";
import {
  setIntermediateLetter,
  setIntermediateResume,
} from "@/helpers/save-intermediate-data";

export default {
  name: "ChangeTemplateModal",
  components: {
    BrowsTemplatesFilters,
    BrowsTemplatesArticle,
    AppEmbedLoader,
  },

  props: {
    currentTemplateId: {
      type: String,
    },
    isResume: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters({
      resumeTemplates: "templates/resumeTemplates",
      letterTemplates: "templates/letterTemplates",
    }),

    templates() {
      return this.isResume ? this.resumeTemplates : this.letterTemplates;
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async getResumeTemplates(filter) {
      this.loading = true;
      try {
        await this.$store.dispatch("templates/getResumeTemplates", filter);
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    async getLetterTemplates() {
      this.loading = true;
      try {
        await this.$store.dispatch("templates/getCoverLetterTemplates");
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    closeChangeTemplateModal() {
      eventBus.$emit("closeChangeTemplateModal");
    },

    async changeTemplate(templateId) {
      await this.$store.dispatch("loader/pending");
      try {
        if (this.isResume) {
          await this.$store.dispatch("resumeCreation/updateResume", {
            template_id: templateId,
          });

          await setIntermediateResume();
        } else {
          await this.$store.dispatch("coverLetterCreation/updateCoverLetter", {
            template_id: templateId,
          });

          await setIntermediateLetter();
        }

        eventBus.$emit("closeChangeTemplateModal");
        eventBus.$emit("closeCreationSidebar");
        eventBus.$emit("changeTemplate");
        AppToast.toastSuccess("Template has been successfully updated!");
      } catch (e) {
        console.error(e);
      }
      await this.$store.dispatch("loader/done");
    },

    keypressHandler(event) {
      if (event.code === "Escape") {
        this.closeChangeTemplateModal();
      }
    },
  },

  async created() {
    this.isResume
      ? await this.getResumeTemplates("all")
      : await this.getLetterTemplates();

    document.addEventListener("keydown", this.keypressHandler);
  },

  beforeDestroy() {
    document.removeEventListener("keydown", this.keypressHandler);
  },
};
</script>

<style scoped lang="scss" src="./index.scss" />
