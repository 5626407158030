import Vue from "vue";

export default {
  setCoverLetter(state, coverLetter) {
    Vue.set(state, "coverLetter", coverLetter);
  },

  mergeCoverLetter(state, coverLetter) {
    let updatedCoverLetter = {};

    if (state.coverLetter) {
      updatedCoverLetter = { ...state.coverLetter };
    }

    Object.assign(updatedCoverLetter, coverLetter);

    Vue.set(state, "coverLetter", updatedCoverLetter);
  },

  setPreviewState(state, stateStatus) {
    state.isLoadLetterPreview = stateStatus;
  },

  setPreviewImage(state, reviewLink) {
    state.coverLetterPreview = reviewLink;
  },

  setPreviewFilesImage(state, fileList) {
    state.coverLetterPreviewImageFiles = fileList;
  },

  setPreviewPDF(state, pdfURL) {
    state.coverLetterPreviewPDF = pdfURL;
  },
};
