import axios from "axios";

const ACCESS_TOKEN_KEY = "access_token";

function setAccessToken(accessToken) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
}

function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

function clearAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  delete axios.defaults.headers.common["Authorization"];
}

export default {
  token: null,
  get() {
    if (!this) {
      return null;
    }
    if (this.token) {
      return this.token;
    }
    const token = getAccessToken();
    this.token = token;
    return token;
  },
  set(token) {
    this.token = token;
    return setAccessToken(token);
  },
  clear() {
    this.token = null;
    return clearAccessToken();
  },
};
