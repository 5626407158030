export default {
  namespaced: true,
  state: {
    isDropBoxLoad: false,
    isGoogleDriveLoad: false,
  },

  getters: {
    isDropBoxLoad(state) {
      return state.isDropBoxLoad;
    },

    isGoogleDriveLoad(state) {
      return state.isGoogleDriveLoad;
    },
  },

  mutations: {
    toggleServiceStateLoad(store, serviceState) {
      store[serviceState.serviceName] = serviceState.serviceState;
    },
  },

  actions: {
    generateDropboxScript({ commit }) {
      if (document.getElementById("dropboxjs")) return;

      let dropBox = document.createElement("script");
      dropBox.setAttribute(
        "src",
        "https://www.dropbox.com/static/api/2/dropins.js"
      );
      dropBox.setAttribute("id", "dropboxjs");
      dropBox.setAttribute("data-app-key", process.env.VUE_APP_DROPBOX_TOKEN);

      document.head.appendChild(dropBox);

      dropBox.addEventListener("load", () => {
        const dropBoxLoadingState = {
          serviceName: "isDropBoxLoad",
          serviceState: true,
        };

        commit("toggleServiceStateLoad", dropBoxLoadingState);
      });
    },

    generateGoogleDriveScript({ commit }) {
      if (document.getElementById("gdrivejs")) return;

      let gDrive = document.createElement("script");
      gDrive.setAttribute("type", "text/javascript");
      gDrive.setAttribute("id", "gdrivejs");
      gDrive.setAttribute("src", "https://apis.google.com/js/api.js");
      document.head.appendChild(gDrive);

      gDrive.addEventListener("load", () => {
        const googleDriveLoadingState = {
          serviceName: "isGoogleDriveLoad",
          serviceState: true,
        };

        commit("toggleServiceStateLoad", googleDriveLoadingState);
      });
    },
  },
};
