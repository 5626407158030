import store from "@/store";

let callDelay;

let isMobileDisplay = false;

document.addEventListener("DOMContentLoaded", function () {
  const resizeObserver = new ResizeObserver((entries) => {
    isMobileDisplay = entries[0].contentRect.width < 992;
  });
  resizeObserver.observe(document.body);
});

/**
 * Sanding data for preview with delay
 * @param resumeHash: [null, String]. Editing or creation resume hash
 * @param allowUpdate
 * @returns {Promise<void>}
 */
export const setIntermediateResume = async function (
  resumeHash = null,
  allowUpdate = false
) {
  if (isMobileDisplay && !allowUpdate) return;

  await store.dispatch("resumeCreation/setPreviewLoadingState", true);

  //clearTimeout(callDelay);

  // Shorten the duration to less than a second for the function call
  callDelay = setTimeout(async function () {
    await store.dispatch("resumeCreation/getResumePreviewAll", resumeHash);

    await store.dispatch("resumeCreation/setPreviewLoadingState", false);
  }, 500);
};

export const setIntermediateLetter = async function (
  letterHash = null,
  allowUpdate = false
) {
  if (isMobileDisplay && !allowUpdate) return;

  await store.dispatch("coverLetterCreation/setPreviewLoadingState", true);

  //clearTimeout(callDelay);

  // Shorten the duration to less than a second for the function call
  callDelay = setTimeout(async function () {
    await store.dispatch(
      "coverLetterCreation/getCoverLetterPreviewAll",
      letterHash
    );

    await store.dispatch("coverLetterCreation/setPreviewLoadingState", false);
  }, 500);
};
