<template>
  <main class="main">
    <slot />
  </main>
</template>

<script>
import { ROUT_PATH } from "@/helpers/rout-constants";

export default {
  name: "EmptyLayout3",
  data() {
    return {
      ROUT_PATH,
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  min-height: 100vh;
  padding-top: 1px;
}
</style>
