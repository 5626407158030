import {
  BROWS_TEMPLATE_NAMES,
  LAYOUT_NAMES,
  ROUT_PATH,
} from "@/helpers/rout-constants";

export default [
  {
    path: ROUT_PATH.BROWS_RESUME_TEMPLATES,
    name: BROWS_TEMPLATE_NAMES.BROWS_RESUME,
    component: () => import("@/views/BrowsTemplates"),
    meta: {
      layout: LAYOUT_NAMES.EMPTY3,
    },
  },
  {
    path: ROUT_PATH.BROWS_LETTERS_TEMPLATES,
    name: BROWS_TEMPLATE_NAMES.BROWS_LETTERS,
    component: () => import("@/views/BrowsTemplates"),
    meta: {
      layout: LAYOUT_NAMES.EMPTY3,
    },
  },
];
