import {
  CREATING_COVER_LETTER_NAMES,
  LAYOUT_NAMES,
  ROUT_PATH,
} from "@/helpers/rout-constants";

export default [
  {
    path: ROUT_PATH.CREATING_COVER_LETTER_PATH.COVER_LETTER_CREATION,
    name: CREATING_COVER_LETTER_NAMES.COVER_LETTER_CREATION,
    component: () =>
      import("@/views/cover-letter-creation/CreationCoverLetter"),
    children: [
      {
        path: ROUT_PATH.CREATING_COVER_LETTER_PATH.STEP_INIT_LETTER,
        name: CREATING_COVER_LETTER_NAMES.STEP_INIT_LETTER,
        component: () =>
          import(
            "@/views/cover-letter-creation/step-init/StepInitialLetterCreation"
          ),
        meta: {
          layout: LAYOUT_NAMES.EMPTY,
          isCreationStep: true,
        },
      },
      {
        path: ROUT_PATH.CREATING_COVER_LETTER_PATH.STEP_1,
        name: CREATING_COVER_LETTER_NAMES.STEP_1,
        component: () =>
          import(
            "@/views/cover-letter-creation/step-1/StepContactInfoLetterCreation"
          ),
        meta: {
          layout: LAYOUT_NAMES.CREATION,
          isCreationStep: true,
          numberCreationStep: 1,
          percentProgress: 2,
        },
      },
      {
        path: ROUT_PATH.CREATING_COVER_LETTER_PATH.STEP_2,
        name: CREATING_COVER_LETTER_NAMES.STEP_2,
        component: () =>
          import(
            "@/views/cover-letter-creation/step-2/StepDetailedInfoLetterCreation"
          ),
        meta: {
          layout: LAYOUT_NAMES.CREATION,
          isCreationStep: true,
          numberCreationStep: 2,
          percentProgress: 80,
        },
      },
      {
        path: ROUT_PATH.CREATING_COVER_LETTER_PATH.STEP_FINAL,
        name: CREATING_COVER_LETTER_NAMES.STEP_FINAL,
        component: () =>
          import(
            "@/views/cover-letter-creation/step-final/StepFinalLetterCreation"
          ),
        meta: {
          layout: LAYOUT_NAMES.DEFAULTNOLINKS,
          isCreationStep: true,
        },
      },
    ],
  },
];
