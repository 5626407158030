import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import {
  SUBSCRIBE_STEPS_NAMES,
  LAYOUT_NAMES,
  ROUT_PATH,
  USER_ACCOUNT_NAMES,
} from "@/helpers/rout-constants";
import authRoutes from "./_auth";
import userRoutes from "./_user";
import browsTemplatesRoutes from "./_brows-templates";
import resumeCreationRoutes from "./_creating-resume";
import coverLetterCreationRoutes from "./_creating-cover-letter";
import templateEditingRoutes from "./_template-editing";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/home/Home"),
    meta: {
      layout: LAYOUT_NAMES.DEFAULT,
    },
  },
  {
    path: ROUT_PATH.TERMS_AND_CONDITIONS,
    name: "Terms and conditions",
    component: () => import("@/views/TermsAndConditions"),
    meta: {
      layout: LAYOUT_NAMES.DEFAULT,
    },
  },
  {
    path: ROUT_PATH.PRIVACY_POLICY,
    name: "Privacy policy",
    component: () => import("@/views/PrivacyPolicy"),
    meta: {
      layout: LAYOUT_NAMES.DEFAULT,
    },
  },
  {
    path: ROUT_PATH.CONTACT_US,
    name: "Contact Us",
    component: () => import("@/views/ContactUs"),
    meta: {
      layout: LAYOUT_NAMES.DEFAULT,
    },
  },
  {
    path: ROUT_PATH.MANAGE_SUB,
    name: "Manage Subscription",
    component: () => import("@/views/Manage"),
    meta: {
      layout: LAYOUT_NAMES.DEFAULT,
    },
  },
  {
    path: ROUT_PATH.SUBSCRIBE_STEPS.SUBSCRIBE_PLANS,
    name: SUBSCRIBE_STEPS_NAMES.SUBSCRIBE_PLANS,
    component: () => import("@/views/subscribe/SubscribePlans"),
    meta: {
      layout: LAYOUT_NAMES.EMPTY2,
    },
  },
  {
    path: ROUT_PATH.SUBSCRIBE_STEPS.PAYMENT_PAGE,
    name: SUBSCRIBE_STEPS_NAMES.PAYMENT_PAGE,
    component: () => import("@/views/subscribe/PaymentPagev2"),
    meta: {
      layout: LAYOUT_NAMES.EMPTY2,
      requiresAuth: true,
    },
  },
  {
    path: ROUT_PATH.SUBSCRIBE_STEPS.THANKYOU_PAGE,
    name: SUBSCRIBE_STEPS_NAMES.THANKYOU_PAGE,
    component: () => import("@/views/subscribe/ThankYouPage"),
    meta: {
      layout: LAYOUT_NAMES.DEFAULT,
      requiresAuth: true,
    },
  },
  {
    path: "*",
    name: "404 not found",
    component: () => import("@/views/NotFound"),
    meta: {
      layout: LAYOUT_NAMES.DEFAULT,
    },
  },
]
  .concat(authRoutes)
  .concat(userRoutes)
  .concat(browsTemplatesRoutes)
  .concat(resumeCreationRoutes)
  .concat(coverLetterCreationRoutes)
  .concat(templateEditingRoutes);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const body = document.querySelector("body");

  if (body.classList.contains("body--overflow")) {
    body.classList.remove("body--overflow");
  }

  if (to.name === "Home" && store.getters["auth/isLoggedIn"]) {
    next({
      name: USER_ACCOUNT_NAMES.USER_DASHBOARD,
    });

    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["auth/isLoggedIn"]) {
      next();
      return;
    }

    next("/login");
  } else {
    next();
  }
});

export default router;
