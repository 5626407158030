import $http from "./BaseHttpServices";

export default {
  getUsersAllResumes() {
    return $http.get("auth/resumes");
  },

  removeUserResumes(resumeHash) {
    return $http.delete(`resume/${resumeHash}`);
  },

  getUsersAllCoverLetters() {
    return $http.get("auth/cover-letters");
  },

  removeUserCoverLetter(letterHash) {
    return $http.delete(`cover-letter/${letterHash}`);
  },

  sendResumeByEmail(emailData) {
    return $http.post("resume/send-email", emailData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  sendCoverLetterByEmail(emailData) {
    return $http.post("cover-letter/send-email", emailData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
};
