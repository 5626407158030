const RESUME_HASH_KEY = "resume_hash";

function setResumeHash(hash) {
  localStorage.setItem(RESUME_HASH_KEY, hash);
}

function getResumeHash() {
  return localStorage.getItem(RESUME_HASH_KEY);
}

function clearResumeHash() {
  localStorage.removeItem(RESUME_HASH_KEY);
}

export default {
  hash: null,
  completedHash: null,
  get() {
    if (!this) {
      return null;
    }

    if (this.hash) {
      return this.hash;
    }

    const hash = getResumeHash();
    this.hash = hash;
    return hash;
  },

  set(hash) {
    this.hash = hash;
    return setResumeHash(hash);
  },
  clear() {
    this.hash = null;
    return clearResumeHash();
  },
};
