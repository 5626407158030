const LETTER_HASH_KEY = "letter_hash";

function setLetterHash(hash) {
  localStorage.setItem(LETTER_HASH_KEY + "_backup", hash); // backup the hash before overwriting it with the new one
  localStorage.setItem(LETTER_HASH_KEY, hash);
}

function getLetterHash() {
  return localStorage.getItem(LETTER_HASH_KEY);
}

function clearLetterHash() {
  localStorage.removeItem(LETTER_HASH_KEY);
  localStorage.removeItem(LETTER_HASH_KEY + "_backup");
}

function getLetterHashBackUp() {
  return localStorage.getItem(LETTER_HASH_KEY + "_backup");
}

export default {
  hash: null,
  get() {
    if (!this) {
      return null;
    }

    if (this.hash) {
      return this.hash;
    }

    const hash = getLetterHash();
    this.hash = hash;
    return hash;
  },
  getBackUp() {
    return getLetterHashBackUp();
  },
  set(hash) {
    this.hash = hash;
    return setLetterHash(hash);
  },
  clear() {
    this.hash = null;
    return clearLetterHash();
  },
};
