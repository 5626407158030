<template>
  <header class="header-section">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col col-lg-3">
          <div class="header-logo">
            <img src="@/assets/images/header-logo.svg" alt="" />
          </div>
        </div>

        <div v-if="!hideLinks" class="col-lg d-none d-lg-block">
          <nav class="header-nav">
            <router-link
              :to="ROUT_PATH.BROWS_RESUME_TEMPLATES"
              class="header-nav__item"
            >
              Resume Templates
            </router-link>
            <router-link
              :to="ROUT_PATH.BROWS_LETTERS_TEMPLATES"
              class="header-nav__item"
            >
              Cover Letter Templates
            </router-link>
          </nav>
        </div>

        <div
          v-if="!hideLinks"
          class="col-lg-3 d-none d-lg-flex justify-content-end"
        >
          <HeaderUser v-if="isLoggedIn" />

          <AppButton
            v-else
            title="Log In"
            className="secondary"
            class="header-auth-btn"
            tag="router-link"
            :to="ROUT_PATH.AUTH.LOGIN"
          />
        </div>

        <div v-if="!hideLinks" class="col-auto d-lg-none">
          <HeaderBurger
            @click.prevent="toggleBurgerMenu"
            :isActive="isOpenBurgerMenu"
          />
        </div>
      </div>
    </div>

    <HeaderBurgerMenu
      v-if="!hideLinks"
      :isOpen="isOpenBurgerMenu"
      @close="closeBurgerMenu"
    />
  </header>
</template>

<script>
import HeaderUser from "./HeaderUserInfo";
import HeaderBurgerMenu from "./HeaderBurgerMenu";
import HeaderBurger from "./HeaderBurger";
import { mapGetters } from "vuex";
import { ROUT_PATH } from "@/helpers/rout-constants";

export default {
  components: { HeaderUser, HeaderBurgerMenu, HeaderBurger },
  props: {
    hideLinks: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
    }),
  },

  watch: {
    $route() {
      this.closeBurgerMenu();
    },
  },

  data() {
    return {
      isOpenBurgerMenu: false,
      ROUT_PATH,
    };
  },

  methods: {
    toggleBurgerMenu() {
      this.isOpenBurgerMenu = !this.isOpenBurgerMenu;
    },

    closeBurgerMenu() {
      this.isOpenBurgerMenu = false;
    },
  },
};
</script>

<style scoped lang="scss" src="./index.scss" />
