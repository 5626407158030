import $http from "./BaseHttpServices";

export default {
  getAllSubscriptionPlans() {
    return $http.get("subscriptions");
  },

  getManageSubscriptionPlans() {
    return $http.get("get-manage-subscriptions-plans");
  },

  subscribeUser(payload) {
    return $http.post("auth/payment/subscribe", payload);
  },
  unsubscribeUser() {
    return $http.post("auth/payment/unsubscribe");
  },
  changeSubscription(payload) {
    return $http.post("auth/payment/change", payload);
  },
};
