import Vue from "vue";

export default {
  setAllResumes(state, resumes) {
    Vue.set(state, "allResumes", resumes);
  },

  setAllCoverLetters(state, letters) {
    Vue.set(state, "allCoverLetters", letters);
  },
};
