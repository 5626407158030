import $http from "./BaseHttpServices";
import AccessToken from "@/helpers/access-token";

export default {
  getUserGeoIP() {
    return $http.get("geo-ip");
  },
  getUserGeoLocation(payload) {
    return $http.get("geo-location", {
      params: {
        longitude: payload.longitude,
        latitude: payload.latitude,
      },
    });
  },
};
