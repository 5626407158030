<template>
  <main class="main">
    <AppHeader :hideLinks="true" />
    <slot />
    <footer class="footer-section">
      <div class="container"></div>
    </footer>
  </main>
</template>

<script>
import AppHeader from "@/components/header/AppHeader";
import { ROUT_PATH } from "@/helpers/rout-constants";

export default {
  name: "EmptyLayout2",
  components: { AppHeader },

  data() {
    return {
      ROUT_PATH,
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  min-height: 100vh;
  padding-top: 1px;
}

header.header-section + * {
  margin-top: 65px;
}
</style>
