import auth from "./AuthServices";
import user from "./UserServices";
import userDashboard from "./UserDashboardServices";
import contact from "./ContactServices";
import resume from "./ResumeServices";
import coverLetter from "./CoverLetterServices";
import editing from "./EditingServices";
import subscribe from "./SubscribeServices";
import geoip from "./GeoIPServices";

export default {
  auth,
  user,
  contact,
  resume,
  coverLetter,
  userDashboard,
  subscribe,
  editing,
  geoip,
};
