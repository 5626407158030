import axios from "axios";
import AccessToken from "@/helpers/access-token";
import AppToast from "@/helpers/toast-methods";
import store from "@/store";
import router from "@/router";

axios.interceptors.request.use(
  (config) => {
    const token = AccessToken.get();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    config.url = `${process.env.VUE_APP_API_URL}/${config.url}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async (error) => {
    const status = error?.response?.status;
    const errors = error?.response?.data?.errors;
    const message = error?.response?.data?.message;
    let convertedErrors = [];

    if (errors && Object.keys(errors).length) {
      convertedErrors = Object.keys(errors).map((key) => {
        return `${key}: ${errors[key][0]}`;
      });
    }

    /**
     * This case was found at the end. There was no time to rewrite the implementation.
     * This IF doesnt display error message for previews
     */
    if (!error?.message?.withoutToast) {
      AppToast.toastError(message, convertedErrors);
    }

    if (status === 401) {
      await store.dispatch("auth/removeAllCredentials");

      await router.push("/login");
    }

    return Promise.reject(error);
  }
);

export default axios;
