<template>
  <button
    class="nav-btn d-flex align-items-center"
    :class="{ 'nav-btn--opened': isActive }"
    @click.prevent="$emit('click', $event)"
  >
    <div class="nav-btn__line">
      <span class="bar1"></span>
      <span class="bar2"></span>
      <span class="bar3"></span>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.nav-btn {
  cursor: pointer;
  padding: 0;
  transition: all 0.3s ease;

  &__line {
    width: 18px;
    height: 18px;
    position: relative;
    z-index: 3;

    span {
      height: 2px;
      background-color: #31343d;
      width: 100%;
      position: absolute;
      left: 0;
      border-radius: 2px;
      transform-origin: center center;
      transition: transform 0.2s ease, margin 0.2s ease 0.2s,
        width 0.2s ease 0.2s, opacity 0.2s ease 0.2s, background-color 0.4s ease,
        left 0.2s ease 0.2s;
    }

    span.bar1 {
      bottom: 50%;
      transform: translateY(50%);
      margin-bottom: 5px;
    }

    span.bar2 {
      bottom: 50%;
      transform: translateY(50%);
    }

    span.bar3 {
      top: 50%;
      transform: translateY(-50%);
      margin-top: 5px;
    }
  }
}

.nav-btn--opened {
  span {
    background-color: $primary;
    transition: transform 0.2s ease 0.2s, margin 0.2s ease, width 0.2s ease,
      left 0.2s ease, background-color 0.4s ease;
  }

  span.bar1 {
    margin: 0;
    transform: rotate(45deg) translateY(50%);
  }

  span.bar2 {
    opacity: 0;
  }

  span.bar3 {
    margin: 0;
    transform: rotate(-45deg) translateY(-50%);
  }
}
</style>
