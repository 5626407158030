<template>
  <div
    class="template"
    :class="{
      'template--selected': selected,
      'template--selected__skip-template':
        selected__skip_template && skip_template == 'on',
    }"
    @click.prevent="openMobilePreview"
  >
    <p v-if="selected && skip_template == 'off'" class="template__label">
      Selected
    </p>

    <figure class="template__img">
      <img
        :src="
          template.image_name ||
          require('@/assets/images/default-template-img.svg')
        "
        alt=""
      />

      <AppButton
        v-if="isChangeTemplateModal && !selected && isMobileWindowWidth"
        title="Select Template"
        className="primary"
        class="apply-template-mobile"
        iconName="PageIcon"
        @click.prevent="chooseTemplate"
      />
    </figure>

    <h3 class="template__title">{{ template.display_name }}</h3>

    <div v-if="!isMobileWindowWidth && !selected" class="template-details">
      <AppButton
        :title="buttonTitle"
        className="primary"
        class="template-details__btn"
        iconName="PageIcon"
        @click.prevent="chooseTemplate"
      />

      <AppButton
        v-if="!isChangeTemplateModal"
        title="Template Preview"
        className="secondary"
        class="template-details__btn"
        iconName="EyeIcon"
        @click.prevent="$emit('openPreview', template.id)"
      />

      <div class="template-counter" v-if="1 == 0">
        <h3 class="template-counter__int">
          {{ template.used_count }}
        </h3>

        <p class="template-counter__subtext">
          {{ counterSubtext }}s created with this template
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrowsTemplatesArticle",

  props: {
    template: {
      type: Object,
      required: true,
    },
    isResume: {
      type: Boolean,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    isChangeTemplateModal: {
      type: Boolean,
      default: false,
    },
    skip_template: {
      type: String,
      default: "off",
    },
    selected__skip_template: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isMobileWindowWidth: window.innerWidth < 992,
    };
  },

  computed: {
    counterSubtext() {
      return this.isResume ? "Resume" : "Cover Letter";
    },
    buttonTitle() {
      if (this.isChangeTemplateModal) return "Apply template";
      return this.isResume ? "Create Resume" : "Create Cover Letter";
    },
  },

  methods: {
    openMobilePreview() {
      if (!this.isMobileWindowWidth || this.selected) return;

      this.$emit("openPreview", this.template.id);
    },

    chooseTemplate() {
      this.$emit("chooseTemplate", this.template.id);
    },
  },
  created() {
    if (this.skip_template == "on") {
      if (this.template.display_name == "Simple") {
        this.selected__skip_template = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.template {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;

  &--selected {
    border: 2px solid $primary;
  }
  &--selected__skip-template {
    border: 2px solid $primary;
    padding: 24px 0 0 0 !important;
    .template__title {
      background-color: $primary;
      color: #fff;
      padding: 20px;
      position: absolute;
      bottom: 0;
    }
  }

  &__label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 16px;
    top: 16px;
    padding: 0 28px;
    height: 32px;
    background-color: $primary;
    font-weight: 500;
    font-size: 12px;
    color: #fff;
    border-radius: 32px;
    z-index: 2;
  }

  &__img {
    padding-top: 338/260 * 100%;
    position: relative;
    margin-bottom: 10px;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &__title {
    border-top: 1px solid #f0f0f0;
    padding-top: 10px;
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    text-align: center;

    @media (min-width: map_get($grid-breakpoints, md)) {
      font-size: 16px;
    }
  }
}

.template-details {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__btn {
    width: 100%;
    margin-bottom: 16px;
    font-size: 18px !important;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .template-counter {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    padding: 24px;
    text-align: center;
    background: #fff;

    &__int {
      font-size: 18px;
    }

    &__subtext {
      font-size: 12px;
      color: $light-gray;
    }
  }
}

.template:hover {
  .template-details {
    opacity: 1;
  }
}

.apply-template-mobile {
  position: absolute !important;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-top: 20px;
  z-index: 20;
  opacity: 0;
}

.template {
  &:hover,
  &:active,
  &:focus {
    .apply-template-mobile {
      opacity: 1;
    }
  }
}
</style>
