const UNCOMPLETED_STEP_KEY = "creation_step";

function setCreationStep(step) {
  localStorage.setItem(UNCOMPLETED_STEP_KEY, step);
}

function getCreationStep() {
  return localStorage.getItem(UNCOMPLETED_STEP_KEY);
}

function clearCreationStep() {
  localStorage.removeItem(UNCOMPLETED_STEP_KEY);
}

export default {
  creationStep: null,
  get() {
    if (!this) {
      return null;
    }

    if (this.creationStep) {
      return this.creationStep;
    }

    const creationStep = getCreationStep();
    this.creationStep = creationStep;
    return creationStep;
  },

  set(creationStep) {
    this.creationStep = creationStep;
    return setCreationStep(creationStep);
  },
  clear() {
    this.creationStep = null;
    return clearCreationStep();
  },
};
