import $http from "./BaseHttpServices";
import LetterHash from "@/helpers/cover-letter-hash";
import CompletedTemplate from "@/helpers/completed-template-hash";
import AccessToken from "@/helpers/access-token";

let uploadLetterRequest = null;
let updatePreviewLetterRequest = null;

export default {
  getCoverLetterPreviewTemplates() {
    return $http.get("cover-letters-templates-previews");
  },
  getCoverLetterJobOptions(group) {
    return $http.get(`cover-letters-sample-group-names?group=${group}`);
  },
  getCoverLetterIndustryOptions() {
    return $http.get("cover-letters-sample-groups");
  },
  getCoverLetter(hash) {
    const letterHash = hash || LetterHash.get();

    return $http.get(`cover-letter/${letterHash}`);
  },
  createCoverLetter(payload) {
    return $http.post("cover-letter", payload);
  },
  getCoverLetterSample(name) {
    return $http.get(`cover-letters-sample?name=${name}`);
  },
  updateCoverLetter(payload) {
    if (uploadLetterRequest) {
      uploadLetterRequest.cancel({ withoutToast: true });
    }

    uploadLetterRequest = $http.CancelToken.source();

    return $http.patch("cover-letter", payload, {
      cancelToken: uploadLetterRequest.token,
    });
  },

  forceUpdateCoverLetter(payload) {
    return $http.patch("cover-letter", payload);
  },

  getCoverLetterPreview(hash) {
    if (updatePreviewLetterRequest) {
      updatePreviewLetterRequest.cancel({ withoutToast: true });
    }

    updatePreviewLetterRequest = $http.CancelToken.source();

    const letterHash = hash || LetterHash.get();

    return $http.get(`cover-letter-preview/${letterHash}`, {
      cancelToken: updatePreviewLetterRequest.token,
      params: {
        token: AccessToken.get() || "",
      },
      responseType: "blob",
    });
  },

  getCoverLetterPreviewAll(hash) {
    if (updatePreviewLetterRequest) {
      updatePreviewLetterRequest.cancel({ withoutToast: true });
    }

    updatePreviewLetterRequest = $http.CancelToken.source();

    const letterHash = hash || LetterHash.get();

    return $http.get(`cover-letter-preview-all/${letterHash}`, {
      cancelToken: updatePreviewLetterRequest.token,
      params: {
        token: AccessToken.get() || "",
      },
    });
  },

  bindLetterToUser() {
    const payload = { cover_letter_hashes: [CompletedTemplate.get().hash] };

    return $http.post("auth/link-cover-letter", payload);
  },

  downloadLetter(hash, type) {
    return $http.get("auth/download-cover-letter", {
      params: { hash, type },
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    });
  },

  duplicateLetter(hash, isHidden = false) {
    return $http.get(`cover-letter/clone/${hash}`, {
      params: { is_hidden: !!isHidden },
    });
  },
};
