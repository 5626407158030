export default {
  user(state) {
    return state.user;
  },

  expiresDate(state) {
    return state.expiresDate;
  },

  isSubscribed(state) {
    return state.isSubscribed;
  },

  defaultUserData() {
    return {
      email: "",
      first_name: "",
      last_name: "",
      password_exists: true,
      phone: "",
      photo: null,
    };
  },
};
