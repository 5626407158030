export default {
  resumeTemplates(state) {
    return state.resumeTemplates;
  },

  letterTemplates(state) {
    return state.letterTemplates;
  },

  resumeFilters(state) {
    return state.filters;
  },
};
