var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field field--focused",class:{
    'field--required': _vm.required,
    'field--error': _vm.isInvalid,
    'field--password': _vm.isPassword,
    'field--textarea': _vm.textarea,
  }},[_c('label',{staticClass:"field__label",attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('sup',[_vm._v("*")]):_vm._e()]),_c('input',{ref:"field",staticClass:"field__input",class:{
      'field__input--sm': _vm.inputHeight === 'sm',
    },attrs:{"id":_vm.id,"disabled":_vm.disabled,"maxlength":"24"},domProps:{"value":_vm._f("formatCardNumber")(_vm.inputValue)},on:{"focus":_vm.focus,"blur":_vm.blur,"input":_vm.oninput,"keypress":function($event){return _vm.isNumber($event)}}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.isInvalid)?_c('div',{staticClass:"error",on:{"mouseenter":_vm.openErrorMessage,"mouseleave":_vm.closeErrorMessage}},[_c('button',{staticClass:"error__btn",attrs:{"type":"button"}},[_c('AppIcon',{attrs:{"componentName":"ErrorIcon"}})],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.isVisibleErrorMessage)?_c('div',{staticClass:"error__message"},[_vm._t("error")],2):_vm._e()])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }