import $http from "./BaseHttpServices";

export default {
  login(payload) {
    return $http.post("auth/login", payload, {
      headers: { "Content-Type": "application/json" },
    });
  },

  loginSocial(payload) {
    return $http.post("auth/google/oauth-callback", payload);
  },

  register(payload) {
    return $http.post("auth/register", payload);
  },

  resetPassword(payload) {
    return $http.post("auth/forget-password", payload);
  },

  setNewPassword(payload) {
    return $http.post("auth/reset-password", payload);
  },

  logout() {
    return $http.post("auth/logout");
  },
};
