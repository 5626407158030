const EDITING_HASH_KEY = "editing_hash";

function setEditingHash(hash) {
  localStorage.setItem(EDITING_HASH_KEY, hash);
}

function getEditingHash() {
  return localStorage.getItem(EDITING_HASH_KEY);
}

function clearEditingHash() {
  localStorage.removeItem(EDITING_HASH_KEY);
}

export default {
  hash: null,
  get() {
    if (!this) {
      return null;
    }

    if (this.hash) {
      return this.hash;
    }

    const hash = getEditingHash();
    this.hash = hash;
    return hash;
  },

  set(hash) {
    this.hash = hash;
    return setEditingHash(hash);
  },
  clear() {
    this.hash = null;
    return clearEditingHash();
  },
};
