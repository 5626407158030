import {
  CREATING_RESUME_NAMES,
  LAYOUT_NAMES,
  ROUT_PATH,
} from "@/helpers/rout-constants";

export default [
  {
    path: ROUT_PATH.CREATING_RESUME_PATH.RESUME_CREATION,
    name: CREATING_RESUME_NAMES.RESUME_CREATION,
    component: () => import("@/views/resume-creation/CreationResume"),
    children: [
      {
        path: ROUT_PATH.CREATING_RESUME_PATH.STEP_INIT,
        name: CREATING_RESUME_NAMES.STEP_INIT,
        component: () =>
          import("@/views/resume-creation/step-init/StepInitialResumeCreation"),
        meta: {
          layout: LAYOUT_NAMES.EMPTY,
          isCreationStep: true,
        },
      },
      {
        path: ROUT_PATH.CREATING_RESUME_PATH.STEP_1,
        name: CREATING_RESUME_NAMES.STEP_1,
        component: () =>
          import("@/views/resume-creation/step-1/StepBaseInfoResumeCreation"),
        meta: {
          layout: LAYOUT_NAMES.CREATION,
          isCreationStep: true,
          numberCreationStep: 1,
          percentProgress: 2,
        },
      },
      {
        path: ROUT_PATH.CREATING_RESUME_PATH.STEP_2,
        name: CREATING_RESUME_NAMES.STEP_2,
        component: () =>
          import(
            "@/views/resume-creation/step-2/StepWorkHistoryResumeCreation"
          ),
        meta: {
          layout: LAYOUT_NAMES.CREATION,
          isCreationStep: true,
          numberCreationStep: 2,
          percentProgress: 10,
        },
      },
      {
        path: ROUT_PATH.CREATING_RESUME_PATH.STEP_3,
        name: CREATING_RESUME_NAMES.STEP_3,
        component: () =>
          import("@/views/resume-creation/step-3/StepEducationResumeCreation"),
        meta: {
          layout: LAYOUT_NAMES.CREATION,
          isCreationStep: true,
          numberCreationStep: 3,
          percentProgress: 30,
        },
      },
      {
        path: ROUT_PATH.CREATING_RESUME_PATH.STEP_4,
        name: CREATING_RESUME_NAMES.STEP_4,
        component: () =>
          import("@/views/resume-creation/step-4/StepSkillsResumeCreation"),
        meta: {
          layout: LAYOUT_NAMES.CREATION,
          isCreationStep: true,
          numberCreationStep: 4,
          percentProgress: 75,
        },
      },
      {
        path: ROUT_PATH.CREATING_RESUME_PATH.STEP_5,
        name: CREATING_RESUME_NAMES.STEP_5,
        component: () =>
          import("@/views/resume-creation/step-5/StepSummaryResumeCreation"),
        meta: {
          layout: LAYOUT_NAMES.CREATION,
          isCreationStep: true,
          numberCreationStep: 5,
          percentProgress: 90,
        },
      },
      {
        path: ROUT_PATH.CREATING_RESUME_PATH.STEP_FINAL,
        name: CREATING_RESUME_NAMES.STEP_FINAL,
        component: () =>
          import("@/views/resume-creation/finalize/FinalStepResumeCreation"),
        meta: {
          layout: LAYOUT_NAMES.DEFAULTNOLINKS,
          isCreationStep: true,
        },
      },
    ],
  },
];
