var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loader-embed",style:({
    width: (_vm.size + "px"),
    height: (_vm.size + "px"),
  })},[_c('div',{style:({
      width: (_vm.size + "px"),
      height: (_vm.size + "px"),
      'border-top-color': _vm.color,
      'border-right-color': _vm.color,
    }),attrs:{"id":"loader-btn"}},[_c('span',{staticClass:"before",style:({
        'border-top-color': _vm.color,
        'border-left-color': _vm.color,
      })}),_c('span',{staticClass:"after",style:({
        'border-top-color': _vm.color,
        'border-right-color': _vm.color,
      })})])])}
var staticRenderFns = []

export { render, staticRenderFns }