<template>
  <main class="main">
    <AppHeader hideLinks="true" />

    <slot />

    <footer class="footer-section">
      <div class="container">
        <figure class="footer-img">
          <img src="@/assets/images/footer-logos.png" alt="" />
        </figure>

        <p class="footer-copy">
          &copy; Copyright {{ new Date().getFullYear() }} Resume-Perfect.com -
          All Rights Reserved
        </p>
      </div>
    </footer>
  </main>
</template>

<script>
import AppHeader from "@/components/header/AppHeader";
import { ROUT_PATH } from "@/helpers/rout-constants";

export default {
  name: "DefaultLayoutNoMenu",
  components: { AppHeader },

  data() {
    return {
      ROUT_PATH,
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  min-height: 100vh;
  padding-top: 1px;
}

header.header-section + * {
  margin-top: 65px;
}

footer.footer-section {
  padding: 56px 0 38px 0;
  border-top: 1px solid #cccdcf;

  @media (min-width: map_get($grid-breakpoints, md)) {
    padding-bottom: 56px;
  }
}

.footer-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 36px;

  @media (min-width: map_get($grid-breakpoints, md)) {
    flex-wrap: wrap;
    flex-direction: row;
  }

  &__item {
    font-size: 14px;
    text-align: center;
    padding: 15px;

    @media (min-width: map_get($grid-breakpoints, lg)) {
      padding: 0 15px;
    }
  }
}

.footer-img {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 32px;
    width: auto;
    margin-bottom: 16px;
  }
}

.footer-copy {
  font-size: 12px;
  text-align: center;
  color: #8c8f96;
}
</style>
