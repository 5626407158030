import Vue from "vue";

export default {
  namespaced: true,
  state: {
    subscriptionPlans: [],
  },

  getters: {
    getSubscriptions(state) {
      return state.subscriptionPlans;
    },
    getManageSubscriptions(state) {
      return state.manageSubscriptionPlans;
    },
  },

  mutations: {
    setSubscriptionPlans(state, subscriptionPlans) {
      Vue.set(state, "subscriptionPlans", subscriptionPlans);
    },
    setManageSubscriptionPlans(state, subscriptionPlans) {
      Vue.set(state, "manageSubscriptionPlans", subscriptionPlans);
    },
  },
  actions: {
    getAllSubscriptionPlans({ commit }) {
      return this._vm.$api.subscribe
        .getAllSubscriptionPlans()
        .then(async (resp) => {
          commit("setSubscriptionPlans", resp.data);

          return resp.data;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    getManageSubscriptionPlans({ commit }) {
      return this._vm.$api.subscribe
        .getManageSubscriptionPlans()
        .then(async (resp) => {
          commit("setManageSubscriptionPlans", resp.data);

          return resp.data;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    subscribeUser(_, payload) {
      return this._vm.$api.subscribe
        .subscribeUser(payload)
        .then((resp) => {
          this.dispatch("user/getUser");
          return resp;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    unsubscribeUser() {
      return this._vm.$api.subscribe
        .unsubscribeUser()
        .then((resp) => {
          this.dispatch("user/getUser");
          return resp;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    changeSubscription(_, payload) {
      return this._vm.$api.subscribe
        .changeSubscription(payload)
        .then((resp) => {
          this.dispatch("user/getUser");
          return resp;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
  },
};
