import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import Vuelidate from "vuelidate";
import Toast from "vue-toastification";
import axios from "@/api/BaseHttpServices";
import VueAxios from "vue-axios";
import VueSocialAuth from "vue-social-auth";
import UUID from "vue-uuid";
import PortalVue from "portal-vue";
import ClickOutsideHandler from "@/directives/click-outside";
import apiService from "@/api";

import DefaultLayout from "@/layouts/DefaultLayout";
import DefaultLayoutNoMenu from "@/layouts/DefaultLayoutNoMenu";
import EmptyLayout from "@/layouts/EmptyLayout";
import AuthLayout from "@/layouts/AuthLayout";
import CreationFlowLayout from "@/layouts/CreationFlowLayout";
import EmptyLayout2 from "@/layouts/EmptyLayout2";
import EmptyLayout3 from "@/layouts/EmptyLayout3";

import AppButton from "@/components/ui/AppButton";
import AppButtonSubmit from "@/components/ui/AppButtonSubmit";
import AppIcon from "@/components/ui/AppIcon";
import AppField from "@/components/ui/AppField";
import AppFieldCardNumber from "@/components/ui/AppFieldCardNumber";
import AppFieldNumber from "@/components/ui/AppFieldNumber";
import AppSelect from "@/components/ui/AppSelect";
import AppModal from "@/components/modal/AppModal";
import AppLoader from "@/components/ui/AppLoader";

Vue.prototype.$api = apiService;

Vue.component("DefaultLayout", DefaultLayout);
Vue.component("DefaultLayoutNoMenu", DefaultLayoutNoMenu);
Vue.component("EmptyLayout", EmptyLayout);
Vue.component("CreationFlowLayout", CreationFlowLayout);
Vue.component("EmptyLayout2", EmptyLayout2);
Vue.component("EmptyLayout3", EmptyLayout3);
Vue.component("AuthLayout", AuthLayout);
Vue.component("AppIcon", AppIcon);
Vue.component("AppButton", AppButton);
Vue.component("AppButtonSubmit", AppButtonSubmit);
Vue.component("AppField", AppField);
Vue.component("AppFieldCardNumber", AppFieldCardNumber);
Vue.component("AppFieldNumber", AppFieldNumber);
Vue.component("AppSelect", AppSelect);
Vue.component("AppLoader", AppLoader);
Vue.component("AppModal", AppModal);

Vue.directive("click-outside", ClickOutsideHandler);

const toastOptions = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 6,
  newestOnTop: true,
};

Vue.use(UUID);
Vue.use(Toast, toastOptions);
Vue.use(Vuelidate);
Vue.use(VueAxios, axios);
Vue.use(PortalVue);
Vue.use(VueSocialAuth, {
  providers: {
    // linkedin: {
    //   clientId: process.env.VUE_APP_CLIENT_ID_LINKEDIN,
    //   redirectUri: `${process.env.VUE_APP_BASE_URL}oauth-callback/linkedin/`,
    //   scope: ["r_emailaddress", "r_liteprofile"],
    // },
    google: {
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      redirectUri: `${process.env.VUE_APP_BASE_URL}oauth-callback/google/`,
    },
  },
});

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
