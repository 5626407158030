import LetterHash from "@/helpers/cover-letter-hash";
import router from "@/router";

export default {
  async getIndustryOptions() {
    return this._vm.$api.coverLetter
      .getCoverLetterIndustryOptions()
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  async getJobOptions(_, jobIndustry) {
    return this._vm.$api.coverLetter
      .getCoverLetterJobOptions(jobIndustry)
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  async getCoverLetter({ commit, dispatch }, hash) {
    return this._vm.$api.coverLetter
      .getCoverLetter(hash)
      .then(async (resp) => {
        commit("setCoverLetter", resp.data);

        return resp.data;
      })
      .catch((err) => {
        LetterHash.clear();
        router.push("/");
        return Promise.reject(err);
      });
  },
  async createCoverLetter({ commit, dispatch }, userData) {
    const description = await dispatch("getCoverLetterSample", userData);
    const letterData = {
      ...userData,
      description,
    };
    return this._vm.$api.coverLetter
      .createCoverLetter(letterData)
      .then((resp) => {
        LetterHash.set(resp.data.hash);
        commit("setCoverLetter", resp.data);
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  /**
   * Can be canceled
   * @param commit
   * @param userData
   * @returns Promise
   */
  async updateCoverLetter({ commit }, userData) {
    const updatedLetterData = {
      hash:
        this.getters["coverLetterCreation/getLetterHash"] || LetterHash.get(),
      ...userData,
    };

    return this._vm.$api.coverLetter
      .updateCoverLetter(updatedLetterData)
      .then((resp) => {
        commit("mergeCoverLetter", updatedLetterData);

        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  /**
   * Can not be canceled
   * @param _
   * @param letterDate
   * @returns Promise
   */
  forceUpdateCoverLetter(_, letterDate) {
    return this._vm.$api.coverLetter
      .forceUpdateCoverLetter(letterDate)
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  async getCoverLetterSample(_, { name }) {
    return this._vm.$api.coverLetter
      .getCoverLetterSample(name)
      .then((resp) => {
        const text = resp.data;
        return [text.introduction, text.body, text.closing].join("");
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  setPreviewLoadingState({ commit }, stateStatus) {
    commit("setPreviewState", stateStatus);
  },

  getCoverLetterPreview({ commit, dispatch }, hash = null) {
    dispatch("setPreviewLoadingState", true);

    return this._vm.$api.coverLetter
      .getCoverLetterPreview(
        hash || this.getters["coverLetterCreation/getCoverLetter"].hash
      )
      .then(async (resp) => {
        // const previewLink = `${resp.data.url}?v=${Date.now()}`;
        const previewLink = `${URL.createObjectURL(resp.data)}`;

        commit("setPreviewImage", previewLink);
        dispatch("setPreviewLoadingState", false);

        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  getCoverLetterPreviewAll({ commit, dispatch }, hash = null) {
    dispatch("setPreviewLoadingState", true);

    return this._vm.$api.coverLetter
      .getCoverLetterPreviewAll(
        hash || this.getters["resumeCreation/getCoverLetter"].hash
      )
      .then(async (resp) => {
        const modifiedPreviewFiles = [];
        const previewFiles =
          resp.data.preview_image_files != undefined
            ? resp.data.preview_image_files
            : "{}";

        const previewPDF =
          resp.data.generated_pdf != undefined ? resp.data.generated_pdf : "";

        const arrPreviewFiles = JSON.parse(previewFiles);
        for (var i = 0; i < arrPreviewFiles.length; i++) {
          modifiedPreviewFiles.push(`${arrPreviewFiles[i]}?v=${Date.now()}`);
        }

        commit("setPreviewFilesImage", modifiedPreviewFiles);
        commit("setPreviewPDF", previewPDF);

        dispatch("setPreviewLoadingState", false);
        return resp.data;
      })
      .catch((err) => {
        dispatch("setPreviewLoadingState", false);
        return Promise.reject(err);
      });
  },

  bindLetterToUser() {
    return this._vm.$api.coverLetter
      .bindLetterToUser()
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  downloadLetter(_, fileInfo) {
    return this._vm.$api.coverLetter
      .downloadLetter(fileInfo.hash, fileInfo.type)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  duplicateLetter(_, duplicateParams) {
    return this._vm.$api.coverLetter
      .duplicateLetter(duplicateParams.hash, duplicateParams.isHidden)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  cleanCoverLetter({ commit }) {
    commit("setCoverLetter", null);
  },
};
