const COMPLETED_TEMPLATE_KEY = "template_completed";

function setCompletedTemplate(hash, type) {
  const completedTemplateInfo = JSON.stringify({
    hash,
    type,
  });

  localStorage.setItem(COMPLETED_TEMPLATE_KEY, completedTemplateInfo);
}

function getCompletedTemplate() {
  const templateInfo = localStorage.getItem(COMPLETED_TEMPLATE_KEY);

  return JSON.parse(templateInfo);
}

function clearCompletedTemplate() {
  localStorage.removeItem(COMPLETED_TEMPLATE_KEY);
}

export default {
  get() {
    return getCompletedTemplate();
  },

  set(templateInfo) {
    return setCompletedTemplate(templateInfo.hash, templateInfo.type);
  },

  clear() {
    return clearCompletedTemplate();
  },
};
