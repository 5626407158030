import Vue from "vue";

export default {
  setUserData(state, userData) {
    Vue.set(state, "user", userData);
  },

  setExpiresDate(state, expiresDate) {
    if (!expiresDate) {
      state.expiresDate = expiresDate;
      return;
    }
    state.expiresDate = expiresDate.slice(0, 10).replace(/-/g, ".");
  },

  setSubscribeState(state, date) {
    const expiresDate = new Date(date).getTime();
    const dateNow = new Date().getTime();
    const isNotExpired = expiresDate >= dateNow;

    state.isSubscribed = isNotExpired;
  },

  removeUserPhoto(state) {
    state.user.photo = null;
  },
};
