import $http from "./BaseHttpServices";

export default {
  getUser() {
    return $http.get("auth/me");
  },

  updateUserInfo(payload) {
    return $http.patch("auth/profile", payload);
  },

  uploadUserPhoto(payload) {
    const formData = new FormData();
    formData.append("photo", payload);

    return $http.post("auth/profile-photo", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  removeUserPhoto() {
    return $http.delete("auth/profile-photo");
  },

  getUsersAllResumes() {
    return $http.get("auth/resumes");
  },
};
