import AppToast from "@/helpers/toast-methods";

export default {
  getAllResumes({ commit }) {
    return this._vm.$api.userDashboard
      .getUsersAllResumes()
      .then((resp) => {
        commit("setAllResumes", resp.data);

        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  removeResume(store, hash) {
    return this._vm.$api.userDashboard
      .removeUserResumes(hash)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  getAllCoverLetters({ commit }) {
    return this._vm.$api.userDashboard
      .getUsersAllCoverLetters()
      .then((resp) => {
        commit("setAllCoverLetters", resp.data);

        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  removeCoverLetter(store, hash) {
    return this._vm.$api.userDashboard
      .removeUserCoverLetter(hash)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  sendResumeByEmail(_, emailData) {
    return this._vm.$api.userDashboard
      .sendResumeByEmail(emailData)
      .then((resp) => {
        AppToast.toastSuccess("Resume was successfully sent");
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  sendLetterByEmail(_, emailData) {
    return this._vm.$api.userDashboard
      .sendCoverLetterByEmail(emailData)
      .then((resp) => {
        AppToast.toastSuccess("Cover letter was successfully send");
        return resp.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};
