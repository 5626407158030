<template>
  <transition name="fade">
    <div v-if="isOpenModal" class="modal-wrap">
      <div class="modal-mask" @click.prevent="$emit('close')" />

      <div class="modal" :style="{ width }">
        <button
          type="button"
          class="modal__close"
          @click.prevent="$emit('close')"
          v-if="!hideClose"
        >
          <AppIcon componentName="CloseIcon" />
        </button>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import $ from "jquery";

export default {
  name: "AppModal",

  props: {
    width: {
      type: String,
      default: "initial",
    },

    isOpenModal: {
      type: Boolean,
      default: false,
    },

    hideClose: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    isOpenModal(isOpenModal) {
      if (isOpenModal) {
        $(".creation-sidebar").addClass("inactive");
        document.querySelector("body").classList.add("body--overflow");

        return;
      }

      $(".creation-sidebar").removeClass("inactive");
      document.querySelector("body").classList.remove("body--overflow");
    },
  },

  data() {
    return {};
  },
};
</script>

<style scoped lang="scss" src="./index.scss" />
