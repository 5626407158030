import AuthModule from "./auth";
import UserModule from "./user";
import LoaderModule from "./loader";
import ContactModule from "./contact-us";
import TemplatesModule from "./templates";
import ResumeCreationModule from "./resume-creation";
import CoverLetterCreationModule from "./cover-letter-creation";
import UserDashboardModule from "./user-dashboard";
import SubscribeModule from "./subscribe";
import FileServicesModule from "./file-services";
import GeoIPModule from "./geo-ip";

export default {
  auth: AuthModule,
  user: UserModule,
  loader: LoaderModule,
  contact: ContactModule,
  templates: TemplatesModule,
  resumeCreation: ResumeCreationModule,
  coverLetterCreation: CoverLetterCreationModule,
  userDashboard: UserDashboardModule,
  subscribe: SubscribeModule,
  fileServices: FileServicesModule,
  geoip: GeoIPModule,
};
