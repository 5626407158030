var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field field--focused",class:{
    'field--required': _vm.required,
    'field--error': _vm.isInvalid,
    'field--textarea': _vm.textarea,
  }},[_c('label',{staticClass:"field__label",attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('sup',[_vm._v("*")]):_vm._e()]),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"field",staticClass:"field__input",class:{
      'field__input--sm': _vm.inputHeight === 'sm',
    },attrs:{"id":_vm.id,"disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,null)>-1:(_vm.inputValue)},on:{"focus":_vm.focus,"blur":_vm.blur,"input":_vm.oninput,"keypress":function($event){return _vm.isNumber($event)},"change":function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"field",staticClass:"field__input",class:{
      'field__input--sm': _vm.inputHeight === 'sm',
    },attrs:{"id":_vm.id,"disabled":_vm.disabled,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputValue,null)},on:{"focus":_vm.focus,"blur":_vm.blur,"input":_vm.oninput,"keypress":function($event){return _vm.isNumber($event)},"change":function($event){_vm.inputValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"field",staticClass:"field__input",class:{
      'field__input--sm': _vm.inputHeight === 'sm',
    },attrs:{"id":_vm.id,"disabled":_vm.disabled,"type":_vm.type},domProps:{"value":(_vm.inputValue)},on:{"focus":_vm.focus,"blur":_vm.blur,"input":[function($event){if($event.target.composing){ return; }_vm.inputValue=$event.target.value},_vm.oninput],"keypress":function($event){return _vm.isNumber($event)}}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.isInvalid)?_c('div',{staticClass:"error",on:{"mouseenter":_vm.openErrorMessage,"mouseleave":_vm.closeErrorMessage}},[_c('button',{staticClass:"error__btn",attrs:{"type":"button"}},[_c('AppIcon',{attrs:{"componentName":"ErrorIcon"}})],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.isVisibleErrorMessage)?_c('div',{staticClass:"error__message"},[_vm._t("error")],2):_vm._e()])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }