<template>
  <section class="auth-section">
    <div class="auth">
      <div class="auth__header">
        <div class="auth-logo">
          <img src="@/assets/images/header-logo.svg" alt="" />
        </div>
      </div>

      <slot />

      <div class="auth__footer">
        {{ isLoginPage ? "Not Registered Yet?" : "Already have an account?" }}
        <router-link
          :to="isLoginPage ? ROUT_PATH.AUTH.REGISTRATION : ROUT_PATH.AUTH.LOGIN"
        >
          {{ isLoginPage ? "Sign Up" : "Log in" }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { ROUT_PATH } from "@/helpers/rout-constants";

export default {
  name: "AuthLayout",

  data() {
    return {
      ROUT_PATH,
    };
  },

  computed: {
    isLoginPage() {
      return this.$route.name === "Login";
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #fff;
}

.auth {
  padding: 30px 20px;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: map_get($grid-breakpoints, md)) {
    max-width: 700px;
  }

  @media (min-width: map_get($grid-breakpoints, lg)) {
    min-width: 500px;
    flex-shrink: 1;
  }

  @media (min-width: map_get($grid-breakpoints, xl)) {
    padding: 50px 90px 40px 90px;
  }

  &__header {
    margin-bottom: 0px;
    text-align: center;

    @media (min-width: map_get($grid-breakpoints, lg)) {
      margin-bottom: 0px;
    }

    .auth-logo {
      display: inline-flex;

      img {
        height: 30px;
        width: auto;

        @media (min-width: map_get($grid-breakpoints, lg)) {
          height: 40px;
        }
      }
    }
  }

  &__footer {
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    color: $body-color;
    margin-top: 40px;

    a {
      font-weight: bold;
      color: $primary;

      &:hover {
        color: $body-color;
      }
    }
  }
}
</style>
