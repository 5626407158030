import { AUTH_NAMES, LAYOUT_NAMES, ROUT_PATH } from "@/helpers/rout-constants";

export default [
  {
    path: ROUT_PATH.AUTH.LOGIN,
    name: AUTH_NAMES.LOGIN,
    component: () => import("@/views/auth/Login"),
    meta: {
      layout: LAYOUT_NAMES.AUTH,
    },
  },
  {
    path: ROUT_PATH.AUTH.REGISTRATION,
    name: AUTH_NAMES.REGISTRATION,
    component: () => import("@/views/auth/Registration"),
    meta: {
      layout: LAYOUT_NAMES.AUTH,
    },
  },
  {
    path: ROUT_PATH.AUTH.RESET_PASSWORD,
    name: AUTH_NAMES.RESET_PASSWORD,
    component: () => import("@/views/auth/ResetPassword"),
    meta: {
      layout: LAYOUT_NAMES.AUTH,
    },
  },
  {
    path: ROUT_PATH.AUTH.NEW_PASSWORD,
    name: AUTH_NAMES.NEW_PASSWORD,
    component: () => import("@/views/auth/NewPassword"),
    meta: {
      layout: LAYOUT_NAMES.AUTH,
    },
  },
];
