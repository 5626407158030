import Vue from "vue";

export default {
  setResume(state, resume) {
    Vue.set(state, "resume", resume);
  },

  mergeResume(state, resume) {
    let updatedResume = {};

    if (state.resume) {
      updatedResume = { ...state.resume };
    }

    Object.assign(updatedResume, resume);

    Vue.set(state, "resume", updatedResume);
  },

  updateResumeField(state, updatedData) {
    state.resume[updatedData.key] = updatedData.value;
  },

  setPreviewLoadingState(state, stateStatus) {
    state.isLoadResumePreview = stateStatus;
  },

  setPreviewImage(state, reviewLink) {
    state.resumePreview = reviewLink;
  },

  setPreviewFilesImage(state, fileList) {
    state.resumePreviewFilesImage = fileList;
  },
  setPreviewPDF(state, pdfURL) {
    state.resumePreviewPDF = pdfURL;
  },
};
