/**
 * Route meta layout names must match the names of the Layouts
 */
export const LAYOUT_NAMES = {
  DEFAULT: "DefaultLayout",
  DEFAULTNOLINKS: "DefaultLayoutNoMenu",
  EMPTY: "EmptyLayout",
  CREATION: "CreationFlowLayout",
  AUTH: "AuthLayout",
  EMPTY2: "EmptyLayout2",
  EMPTY3: "EmptyLayout3",
};

/**
 * Route names must match the names of the auth views
 */
export const AUTH_NAMES = {
  LOGIN: "Login",
  REGISTRATION: "Registration",
  RESET_PASSWORD: "ResetPassword",
  NEW_PASSWORD: "NewPassword",
};

export const BROWS_TEMPLATE_NAMES = {
  BROWS_RESUME: "Brows resume templates",
  BROWS_LETTERS: "Brows letters templates",
};

export const SUBSCRIBE_STEPS_NAMES = {
  SUBSCRIBE_PLANS: "Prices",
  PAYMENT_PAGE: "Payment",
  THANKYOU_PAGE: "Thankyou",
};

export const USER_ACCOUNT_NAMES = {
  USER_ACCOUNT: "User account",
  USER_DASHBOARD: "User dashboard",
  USER_RESUME_DETAILS: "Resume template details",
  USER_LETTER_DETAILS: "Cover letter template details",
};

export const TEMPLATE_EDITING_NAMES = {
  RESUME_EDITING: "Resume editing",
  COVER_LETTER_EDITING: "Cover letter editing",
};

export const CREATING_RESUME_NAMES = {
  RESUME_CREATION: "Creation resume",
  STEP_INIT: "Init step",
  STEP_1: "Step 1 (base information)",
  STEP_2: "Step 2 (work history)",
  STEP_3: "Step 3 (education)",
  STEP_4: "Step 4 (skills)",
  STEP_5: "Step 5 (professional summary)",
  STEP_FINAL: "Finalize step",
};

export const CREATING_COVER_LETTER_NAMES = {
  COVER_LETTER_CREATION: "Creation cover letter",
  STEP_INIT_LETTER: "Init letter step",
  STEP_1: "Step 1 (contact information)",
  STEP_2: "Step 2 (letter body)",
  STEP_FINAL: "Final step",
};

/**
 * Route paths
 */
export const ROUT_PATH = {
  AUTH: {
    LOGIN: "/login",
    REGISTRATION: "/registration",
    RESET_PASSWORD: "/reset-password",
    NEW_PASSWORD: "/new-password",
  },
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  PRIVACY_POLICY: "/privacy-policy",
  MANAGE_SUB: "/manage",
  CONTACT_US: "/contact-us",
  USER_ACCOUNT: "/account",
  USER_DASHBOARD: "/dashboard",
  USER_TEMPLATES: {
    USER_RESUME_DETAILS: "/resume/:id",
    USER_LETTER_DETAILS: "/letter/:id",
  },
  BROWS_RESUME_TEMPLATES: "/brows-resume-templates",
  BROWS_LETTERS_TEMPLATES: "/brows-letters-templates",
  SUBSCRIBE_STEPS: {
    SUBSCRIBE_PLANS: "/prices",
    PAYMENT_PAGE: "/payment",
    THANKYOU_PAGE: "/thankyou",
  },
  CREATING_RESUME_PATH: {
    RESUME_CREATION: "/resume-creation",
    STEP_INIT: "initialisation",
    STEP_1: "base-information",
    STEP_2: "work-history",
    STEP_3: "education",
    STEP_4: "skills",
    STEP_5: "professional-summary",
    STEP_FINAL: "finalize",
  },
  CREATING_COVER_LETTER_PATH: {
    COVER_LETTER_CREATION: "/cover-letter-creation",
    STEP_INIT_LETTER: "initialisation",
    STEP_1: "contact-information",
    STEP_2: "letter-body",
    STEP_FINAL: "final-step",
  },
  TEMPLATE_EDITING: {
    RESUME_EDITING: "/resume-editing",
    COVER_LETTER_EDITING: "/cover-letter-editing",
  },
};
